import axios from 'axios';
import { ethers } from 'ethers';
import { wRoseAddress } from './constants';
const rpcUrl = 'https://emerald.oasis.dev';

export async function getBulkPairDataNoGraph(pairAddresses: string[], rosePriceUsd: number) {
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  const pairAbi = [
    'function getReserves() external view returns (uint112 reserve0, uint112 reserve1, uint32 blockTimestampLast)',
    'function token0() external view returns (address)',
    'function token1() external view returns (address)',
    'function totalSupply() external view returns (uint256)',
  ];
  const pairs = pairAddresses.map((pairAddress) => {
    return new ethers.Contract(pairAddress, pairAbi, provider);
  });
  const pairData = await Promise.all(
    pairs.map(async (pair) => {
      const totalSupply = Number(ethers.utils.formatEther(await pair.totalSupply()));
      const [reserve0, reserve1] = await pair.getReserves();
      // if any is the eth token, then reutrn the price in native token terms
      const token0 = await pair.token0();
      const token1 = await pair.token1();
      const token0Reserve = parseFloat(ethers.utils.formatEther(reserve0));
      const token1Reserve = parseFloat(ethers.utils.formatEther(reserve1));
      let price = 0;
      if (token0.toLowerCase() === wRoseAddress.toLowerCase()) {
        price = token0Reserve / token1Reserve;
      } else {
        price = token1Reserve / token0Reserve;
      }

      const wroseReserve = token1.toLowerCase() === wRoseAddress.toLowerCase() ? token1Reserve : token0Reserve;
      const lpPriceUSD = wroseReserve * rosePriceUsd * 2 / totalSupply;
      const priceInUsd = rosePriceUsd * price;

      return {
        price,
        priceInUsd,
        lpPriceUSD,
        token1Reserve,
        token0Reserve,
        token0,
        token1
      };
    })
  );
  return pairData;
}


const findClosestBlock = async (timestamp: number, startBlock: number, endBlock: number): Promise<number> => {
  // while (startBlock <= endBlock) {
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  let midBlock = Math.floor((startBlock + endBlock) / 2);
  const midBlockTimestamp = (await provider.getBlock(midBlock)).timestamp;
  if (midBlockTimestamp < timestamp) {
    startBlock = midBlock + 1;
  } else if (midBlockTimestamp > timestamp) {
    endBlock = midBlock - 1;
  } else {
    return midBlock; // Exact match found
  }
  //}
  // Return the closest block number if exact match is not found
  return startBlock;
};

export const getRosePrice = async () => {
  try {
    const dataFromLocalStorage = localStorage.getItem('rosePrice');
    let data = dataFromLocalStorage ? JSON.parse(dataFromLocalStorage) : [0, 0];
    const timeFromLocalStorage = localStorage.getItem('rosePriceTime');
    let time = timeFromLocalStorage ? JSON.parse(timeFromLocalStorage) : 0;
    const currentTime = Date.now();
    const timeDifference = currentTime - time;
    const cacheDuration = 1000 * 60 * 20; // Cache valid for 20 minutes

    if (timeDifference < cacheDuration) {
      return [data[0]];
    }

    if (data[0] !== 0 && data[1] !== 0) {
      return [data[0]]
    }

    const response = await fetch(
      'https://api.binance.com/api/v3/ticker/price?symbol=ROSEUSDT'
    );
    const pricesJson = await response.json();
    const dataS = pricesJson.price;
    data = [dataS];
    localStorage.setItem('rosePrice', JSON.stringify(data));
    localStorage.setItem('rosePriceTime', JSON.stringify(Date.now()));
    return data;
  } catch (error) {
    console.error('Error fetching price:', error);
    throw error; // or return a default value
  }
}

export const getBlocksFromTimestampsNoGraph = async (timestamps: number[]): Promise<{ number: number }[]> => {
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  const latestBlock = await provider.getBlock('latest');
  const latestBlockNumber = latestBlock.number;

  const blockNumbers = await Promise.all(timestamps.map(async (timestamp) => {
    const blockNumber = await findClosestBlock(timestamp, 1, latestBlockNumber);
    return { number: blockNumber };
  }));

  return blockNumbers;
};

export const getBlockFromTimestampNoGraph = async (timestamp: number): Promise<{ number: number }> => {
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  const latestBlock = await provider.getBlock('latest');
  const latestBlockNumber = latestBlock.number;

  const blockNumber = await findClosestBlock(timestamp, 1, latestBlockNumber);
  return { number: blockNumber };
}

// Fetch data for a single LP pair
const fetchPairDataNoGraph = async (pairAddress: string) => {
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  const pairAbi = [
    'function getReserves() external view returns (uint112 reserve0, uint112 reserve1, uint32 blockTimestampLast)',
    'function token0() external view returns (address)',
    'function token1() external view returns (address)',
    'function totalSupply() external view returns (uint256)',
  ];
  const lpContract = new ethers.Contract(pairAddress, pairAbi, provider);

  // Example: Fetching reserves and total supply
  const [reserve0, reserve1] = await lpContract.getReserves();
  const totalSupply = await lpContract.totalSupply();

  // Format and return the data as needed
  return {
    reserve0: reserve0.toString(),
    reserve1: reserve1.toString(),
    totalSupply: totalSupply.toString(),
    // Add other relevant data
  };
};

export { fetchPairDataNoGraph };


//mock function getBulkPairData
// async function getBulkPairData(pairList: string[], ethPrice: number) {
//   const [t1, t2, tWeek] = getTimestampsForChanges();
//   let [{ number: b1 }, { number: b2 }, { number: bWeek }] = await getBlocksFromTimestampsNoGraph([t1, t2, tWeek]);

//   try {
//     const currentDataPromises = pairList.map(pairAddress => fetchPairDataNoGraph(pairAddress));
//     const oneDayDataPromises = pairList.map(pairAddress => fetchPairDataNoGraph(pairAddress, b1));
//     const twoDayDataPromises = pairList.map(pairAddress => fetchPairDataNoGraph(pairAddress, b2));
//     const oneWeekDataPromises = pairList.map(pairAddress => fetchPairDataNoGraph(pairAddress, bWeek));

//     const [currentData, oneDayData, twoDayData, oneWeekData] = await Promise.all([
//       Promise.all(currentDataPromises),
//       Promise.all(oneDayDataPromises),
//       Promise.all(twoDayDataPromises),
//       Promise.all(oneWeekDataPromises)
//     ]);

//     // Process and combine the data
//     const combinedData = currentData.map((data, index) => {
//       const oneDay = oneDayData[index];
//       const twoDay = twoDayData[index];
//       const oneWeek = oneWeekData[index];

//       // Calculate APR and other metrics here
//       // Example: const baseApy = ...

//       return {
//         ...data,
//         oneDay,
//         twoDay,
//         oneWeek,
//         // baseApy,
//         // ... other combined metrics
//       };
//     });

//     return combinedData;
//   } catch (e) {
//     console.log(e);
//     throw e;
//   }
// }