import {FC, useEffect, useState} from 'react'
import './connect-menu.scss'
import {useWeb3Context} from '../../hooks'
import {ellipseAddress} from './utilities'
import clsx from 'clsx'
import {DEFAULT_NETWORK} from '../constants'

interface ConnectMenuProps {
  dark?: boolean
  reload: true
}

const ConnectMenu: FC<ConnectMenuProps> = ({dark = false, reload = true}) => {
  const {connect, disconnect, connected, providerChainID, address, checkWrongNetwork} =
    useWeb3Context()
  const [isConnected, setConnected] = useState(connected)

  let buttonText = 'Connect Wallet'
  let clickFunc: any = () => connect(reload)
  let buttonStyle = {}

  if (isConnected) {
    buttonText = 'Disconnect'
    clickFunc = disconnect
  }

  /* if (pendingTransactions && pendingTransactions.length > 0) {
        buttonText = `${pendingTransactions.length} Pending `;
        clickFunc = () => {};
    } */

  if (isConnected && providerChainID !== DEFAULT_NETWORK) {
    buttonText = 'Wrong network'
    buttonStyle = {backgroundColor: 'rgb(255, 67, 67)'}
    clickFunc = () => {
      checkWrongNetwork()
    }
  }

  useEffect(() => {
    setConnected(connected)
  }, [connected])

  return (
    <>
      {connected && <div className='btn btn-dark me-3 btn-sm'>{ellipseAddress(address)}</div>}
      <button
        className={clsx('btn btn-sm', dark ? 'btn-dark' : 'btn-light')}
        type='button'
        style={buttonStyle}
        onClick={clickFunc}
      >
        <span>{buttonText}</span>
        {/* pendingTransactions.length > 0 && (
                <div className="connect-button-progress">
                    <CircularProgress size={15} color="inherit" />
                </div>
            ) */}
      </button>
    </>
  )
}

export default ConnectMenu
