import { TokenStaking } from '../../utils/farm'
import { Header, TokenImage } from './TokenMiniDetail.styled'

interface TokenStakeFormProps {
  token: TokenStaking
  ratio: string
}

const HeaderImageTokenAutoCompounder = ({ token, ratio }: TokenStakeFormProps) => {
  return (
    <>
      <Header>
        <div className='d-flex justify-content-end'>
          <TokenImage>
            {token.isLp ? (
              <>
                {token.token0?.image && <img src={token.token0?.image} alt={token.token0.symbol} />}
                {token.token1?.image && <img src={token.token1?.image} alt={token.token1.symbol} />}
              </>
            ) : (
              token.image && <img src={token.image} alt={token.name} />
            )}
          </TokenImage>
        </div>
        {token.name}
      </Header>
      <div className="text-center">
        <span className='badge badge-secondary'>{ratio}</span>
      </div>
    </>
  )
}

export default HeaderImageTokenAutoCompounder
