import { useState, FC } from 'react'
import { PoolValues, TokenStaking } from '../../utils/farm'
import { formatValueWithoutComma, formatValueWithoutCommaCero, formatNumber } from '../../utils/format'
import 'react-toastify/dist/ReactToastify.css'
import {
  Box,
  BoxWrap,
  Balances,
  InputAmount,
  Button,
  Body,
  BodyItem,
  BodyItemTitle,
  BodyItemValue,
  HarvestWrap,
  BoxSpace,
  TextSmall,
  ModalContent,
  ModalHeader,
  TextPriceLP,
} from './TokenStake.styled'
import { ToastContainer, toast } from 'react-toastify'
import BlockUi from '@availity/block-ui'
import Modal from 'react-modal'
import HeaderImageToken from './HeaderImageToken'
import ConnectMenu from '../../utils/connect-button'
import { addNetworkByChain } from '../../utils/addNetworks'
import { DEFAULT_NETWORK } from '../../utils/constants'
import { useWeb3Context } from '../../hooks'
import TokenMiniDetail from "./TokenMiniDetail"
import CloseIcon from '../../assets/icons/close.svg'

interface TokenStakeFormProps {
  token: TokenStaking
  poolValues: PoolValues
  updatePool: (token: TokenStaking) => void
  wrongNetwork: boolean
  onSelectToken: (token: TokenStaking) => void
  selected: boolean,
}

enum StakeAction {
  Stake = 'Stake',
  Unstake = 'Unstake',
}

const TokenStakeForm: FC<TokenStakeFormProps> = ({
  token,
  poolValues,
  updatePool,
  wrongNetwork,
  onSelectToken,
  selected,
}) => {
  const { connected, web3, address, } = useWeb3Context()
  const [amount, setAmount] = useState('')
  const [disabledForm, setDisabledForm] = useState(false)
  const [stateStakeAction, setStateStakeAction] = useState<StakeAction>(StakeAction.Stake)
  const [showModal, setShowModal] = useState(false)


  const isInvalidAmount = (): boolean => {
    if (amount === '') {
      return true
    }
    const amountNumber = Number(amount)
    if (isNaN(amountNumber)) {
      return true
    }
    if (amountNumber <= 0) {
      return true
    }
    return false
  }

  const handleHarvest = async () => {
    try {
      toast('Sending transaction...', {
        autoClose: false,
        toastId: 'harvest',
        type: toast.TYPE.INFO,
      })
      setDisabledForm(true)
      await poolValues.actions.masterChef.depositToPool('0')
      toast.update('harvest', {
        render: 'Harvest completed',
        autoClose: 4000,
        type: toast.TYPE.SUCCESS,
      })
      setDisabledForm(false)
      updatePool(token)
    } catch (error: any) {
      setDisabledForm(false)
      toast.update('harvest', {
        render: error,
        autoClose: 4000,
        type: toast.TYPE.ERROR,
      })
    }
  }

  const handleDeposit = async () => {
    if (isInvalidAmount()) {
      toast.error('Invalid amount')
      return
    }
    try {
      toast('Sending transaction...', {
        autoClose: false,
        toastId: 'deposit',
        type: toast.TYPE.INFO,
      })
      setDisabledForm(true)
      await poolValues.actions.masterChef.depositToPool(amount)
      toast.update('deposit', {
        render: 'Deposit completed',
        autoClose: 4000,
        type: toast.TYPE.SUCCESS,
      })
      setAmount('')
      setDisabledForm(false)
      updatePool(token)
      setShowModal(false)
    } catch (error: any) {
      setDisabledForm(false)
      toast.update('deposit', {
        render: error,
        autoClose: 4000,
        type: toast.TYPE.ERROR,
      })
      setShowModal(false)
    }
  }

  const handleWithdraw = async () => {
    if (isInvalidAmount()) {
      toast.error('Invalid amount')
      return
    }
    try {
      setDisabledForm(true)
      toast('Sending transaction...', {
        autoClose: false,
        toastId: 'withdraw',
        type: toast.TYPE.INFO,
      })
      await poolValues.actions.masterChef.withdrawFromPool(amount)
      setAmount('')
      toast.update('withdraw', {
        render: 'Withdraw completed',
        autoClose: 4000,
        type: toast.TYPE.SUCCESS,
      })
      setDisabledForm(false)
      setShowModal(false)
      updatePool(token)
    } catch (error: any) {
      setDisabledForm(false)
      toast.update('withdraw', {
        render: error,
        autoClose: 4000,
        type: toast.TYPE.ERROR,
      })
      setShowModal(false)
    }
  }
  
  const handleEmergencyWithdraw = async () => {
    try {
      setDisabledForm(true)
      toast('Sending transaction...', {
        autoClose: false,
        toastId: 'withdraw',
        type: toast.TYPE.INFO,
      })
      await poolValues.actions.masterChef.emergencyWithdrawFromPool()
      setAmount('')
      toast.update('withdraw', {
        render: 'Emergency Withdraw completed',
        autoClose: 4000,
        type: toast.TYPE.SUCCESS,
      })
      setDisabledForm(false)
      setShowModal(false)
      updatePool(token)
    } catch (error: any) {
      setDisabledForm(false)
      toast.update('withdraw', {
        render: error,
        autoClose: 4000,
        type: toast.TYPE.ERROR,
      })
      setShowModal(false)
    }
  }

  const handleApproveToken = async () => {
    try {
      setDisabledForm(true)
      toast('Sending transaction...', {
        autoClose: false,
        toastId: 'approve',
        type: toast.TYPE.INFO,
      })
      await poolValues.actions.approveToken(address)
      updatePool(token)
      toast('Token approved...', {
        autoClose: false,
        toastId: 'approve',
        type: toast.TYPE.SUCCESS,
      })
      setDisabledForm(false)
    } catch (error: any) {
      toast.update('approve', {
        render: error,
        autoClose: 4000,
        type: toast.TYPE.ERROR,
      })
      setDisabledForm(false)
      setShowModal(false)
    }
  }

  const closeModal = () => {
    setShowModal(false)
    setAmount('')
  }

  const modalStake = () => {
    return (
      <Modal
        isOpen={showModal}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        <ModalContent>
          <ModalHeader>
            <h3>{stateStakeAction === StakeAction.Stake ? 'Stake' : 'Unstake'}</h3>
            <button onClick={closeModal}>
              <img src={CloseIcon} alt="close" style={{ width: '1.25rem' }} />
            </button>
          </ModalHeader>

          <div className='d-flex justify-content-between w-100 pb-2'>
            <span>Amount</span>
            {stateStakeAction === StakeAction.Stake ? (
              <span>
                {formatValueWithoutCommaCero(poolValues.tokenBalance || '0', 18, 2, 2)}
                <TextSmall>{token.symbol}</TextSmall>
              </span>
            ) : (
              <span>
                {formatValueWithoutCommaCero(poolValues.tokenStaked || '0', 18, 2, 2)}
                <TextSmall>{token.symbol}</TextSmall>
              </span>
            )}
          </div>

          <InputAmount>
            <input
              type='number'
              inputMode='decimal'
              autoComplete='off'
              value={amount}
              className='form-control'
              aria-label='Token Amount'
              title='Token Amount'
              autoCorrect='off'
              pattern='^[0-9]*[.,]?[0-9]*$'
              placeholder='0.0'
              minLength={1}
              maxLength={79}
              spellCheck={false}
              onChange={(e) => {
                setAmount(e.target.value)
              }}
            />
            <button
              className='btn btn-dark'
              onClick={() => {
                setAmount(
                  formatValueWithoutComma(
                    stateStakeAction === StakeAction.Stake
                      ? poolValues.tokenBalance
                      : poolValues.tokenStaked || '0'
                  )
                )
              }}
            >
              MAX
            </button>
          </InputAmount>

          {connected ? (
            poolValues.isApproved ? (
              stateStakeAction === StakeAction.Stake ? (
                <Button
                  className='btn btn-dark'
                  disabled={!poolValues.isSufficientBalance}
                  onClick={handleDeposit}
                >
                  {!poolValues.isSufficientBalance ? 'Insufficient amount' : 'Stake'}
                </Button>
              ) : (
                <Button
                  className='btn btn-dark'
                  disabled={!poolValues.hasStaked}
                  onClick={handleWithdraw}
                >
                  {!poolValues.hasStaked ? 'Insufficient amount' : 'Unstake'}
                </Button>
              )
            ) : (
              <Button className='btn btn-dark' onClick={handleApproveToken}>
                Approve {token.symbol}
              </Button>
            )
          ) : (
            <ConnectMenu reload />
          )}
        </ModalContent>
      </Modal>
    )
  }

  const buttonsStakeUnstake = () => {
    return (
      <>
        <Button
          className='btn btn-dark'
          disabled={!poolValues.isSufficientBalance}
          onClick={() => {
            setShowModal(true)
            setStateStakeAction(StakeAction.Stake)
          }}
        >
          {!poolValues.isSufficientBalance ? 'Insufficient amount' : 'Stake'}
        </Button>
        <Button
          className='btn btn-success'
          onClick={handleEmergencyWithdraw}
        >
          EMERGENCY WITHDRAW
        </Button>
      </>
    )
  }

  const handleSelectToken = (token: TokenStaking) => {
    onSelectToken(token)
  }

  if (!selected) {
    return (
      <BlockUi blocking={disabledForm}>
        <TokenMiniDetail
          token={token}
          poolValues={poolValues}
          handleHarvest={handleHarvest}
          onSelect={handleSelectToken}
        />
        <ToastContainer />
      </BlockUi>
    )
  }


  return (
    <>
      <BlockUi blocking={disabledForm} className="h-100">
        <BoxWrap>
          <BoxSpace className='card h-100'>
            <HeaderImageToken token={token} allocPoint={poolValues.info.allocPoint} />
            <div>
              <Balances className='mt-8'>
                <BodyItem>
                  <BodyItemTitle>YOUR STAKE</BodyItemTitle>
                  <BodyItemValue>
                    {formatNumber(formatValueWithoutCommaCero(poolValues.tokenStaked || '0', 18, 10, 3))}
                    <TextSmall>{token.symbol}</TextSmall>
                    <TextPriceLP>
                      ~{' '}
                      {formatNumber(
                        poolValues.lpPrice *
                        parseFloat(formatValueWithoutCommaCero(poolValues.tokenStaked || '0', 18))
                        , 2)}{' '}
                      USD
                    </TextPriceLP>
                  </BodyItemValue>
                </BodyItem>
                <BodyItem>
                  <BodyItemTitle>AVAILABLE IN WALLET</BodyItemTitle>
                  <BodyItemValue>
                    {formatValueWithoutCommaCero(poolValues.tokenBalance || '0', 18, 2, 2)}
                    <TextSmall>{token.symbol}</TextSmall>
                    <TextPriceLP>
                      ~{' '}
                      {formatNumber(
                        poolValues.lpPrice *
                        parseFloat(formatValueWithoutCommaCero(poolValues.tokenBalance || '0', 18))
                        , 2)}{' '}
                      USD
                    </TextPriceLP>
                  </BodyItemValue>
                </BodyItem>
              </Balances>
            </div>

            {connected ? (
              wrongNetwork ? (
                <div className='text-center'>
                  <h2>Wrong Network</h2>
                  <button
                    onClick={() => {
                      if (connected) addNetworkByChain(web3, DEFAULT_NETWORK)
                    }}
                    className='btn btn-dark'
                  >
                    Change Network
                  </button>
                </div>
              ) : poolValues.isApproved ? (
                buttonsStakeUnstake()
              ) : (
                <Button className='btn btn-dark' onClick={handleApproveToken}>
                  Approve {token.symbol}
                </Button>
              )
            ) : (
              <ConnectMenu reload />
            )}
          </BoxSpace>
          <div>
            <Box className='card mb-3'>
              <Body>
                <BodyItem>
                  <BodyItemTitle>Your Share</BodyItemTitle>
                  <BodyItemValue>{formatNumber(poolValues.sharedPercentage, 2) || 0}%</BodyItemValue>
                </BodyItem>
                <BodyItem>
                  <BodyItemTitle>APR</BodyItemTitle>
                  <BodyItemValue>0%</BodyItemValue>
                </BodyItem>
              </Body>
            </Box>
            <Box className='card'>
              <Body>
                <BodyItem>
                  <BodyItemTitle>Total Staked</BodyItemTitle>
                  <BodyItemValue aria-label='aimc'>
                    {formatNumber(formatValueWithoutCommaCero(poolValues.totalStaked || '0', 18, 2, 2), 2)}
                    <TextSmall>{token.symbol}</TextSmall>
                  </BodyItemValue>
                </BodyItem>
                <BodyItem>
                  <BodyItemTitle>TVL</BodyItemTitle>
                  <BodyItemValue>${formatNumber(poolValues.tvl)}</BodyItemValue>
                </BodyItem>
              </Body>
            </Box>
          </div>
        </BoxWrap>
      </BlockUi>
      <ToastContainer />
      {modalStake()}
    </>
  )
}

export default TokenStakeForm
