import { FC } from 'react'
import { MasterLayout } from '../_metronic/layout/MasterLayout'
import { ErrorsPage } from './modules/errors/ErrorsPage'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { Redirect, Route, Switch } from 'react-router-dom'
import Farm from './pages/Farm/Farm'

const Routes: FC = () => {
  return (
    <>
      <Switch>
        <Route path='/error' component={ErrorsPage} />
        <MasterLayout>
          <Switch>
            <Route exact path='/' component={Farm} />
            <Route exact path='/lair' component={() => {
              return <Farm isLair />
            }} />
            <Redirect to='error/404' />
          </Switch>
        </MasterLayout>
      </Switch>
      <MasterInit />
    </>
  )
}

export { Routes }
