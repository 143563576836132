import { FC, useEffect, useState } from "react"
import Modal from 'react-modal'
import { PoolValues } from "../../../../utils/farm";
import { HeaderImageStyled } from "../../../components/HeaderImageToken.styled";
import { Header, TokenImage } from "../../../components/TokenMiniDetail.styled";
import { HeaderContent, ModalContent, ModalHeader } from "./ModalHarvestAll.styled"
import { ToastContainer, toast } from 'react-toastify'
import { Button, TextSmall } from "../../../components/TokenStake.styled";
import { formatNumber, formatValueWithoutCommaCero } from "../../../../utils/format";

interface ModalHarvestAllProps {
  showModal: boolean
  closeModal: () => void
  farmsUsersList: string[]
  poolsValues: any
}

const ModalHarvestAll: FC<ModalHarvestAllProps> = ({ showModal, closeModal, farmsUsersList, poolsValues }) => {
  const [pools, setPools] = useState<any[]>([]);
  const [currentExecute, setCurrentExecute] = useState<string[]>([]);
  const [errorsHarvest, setErrorsHarvest] = useState<string[]>([]);
  const [doneHarvest, setDoneHarvest] = useState<string[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoaded) {
      Object.keys(poolsValues).forEach(poolAddress => {
        if (farmsUsersList.includes(poolsValues[poolAddress].token.index) && !pools.some(pool => pool.address === poolAddress)) {
          setPools(prevPools => [...prevPools, poolsValues[poolAddress]]);
        }
      })
    }
    setIsLoaded(true)
  }, [poolsValues])

  const handleStart = async () => {
    if (currentExecute.length !== 0) return;
    setCurrentExecute([]);
    setErrorsHarvest([]);
    setDoneHarvest([]);
    const promises = []
    for (let i = 0; i < pools.length; i++) {
      const pool: PoolValues = pools[i];
      promises.push(async () => {
        try {
          toast('Sending transaction...', {
            autoClose: false,
            toastId: 'harvest',
            type: toast.TYPE.INFO,
          })
          setCurrentExecute(prevCurrent => [...prevCurrent, pool.address]);
          await pool.actions.masterChef.depositToPool('0')
          setCurrentExecute(prevCurrent => prevCurrent.filter(address => address !== pool.address));
          setDoneHarvest(prevDone => [...prevDone, pool.address]);
          toast.update('harvest', {
            render: 'Harvest completed',
            autoClose: 4000,
            type: toast.TYPE.SUCCESS,
          })
          // updatePool(token)
        } catch (error: any) {
          setCurrentExecute(prevCurrent => prevCurrent.filter(address => address !== pool.address));
          setErrorsHarvest(prevErrors => [...prevErrors, pool.address]);
          toast.update('harvest', {
            render: error,
            autoClose: 4000,
            type: toast.TYPE.ERROR,
          })
        }
      })
    }

    await Promise.all(promises.map(p => p()));
    setCurrentExecute([]);
  }

  return (
    <Modal
      isOpen={showModal}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
      ariaHideApp={false}
    >
      <ModalContent>
        <ModalHeader>
          <h3>Harvest all rewards of your pools</h3>
          <button onClick={closeModal}>
            <svg fill='#000000' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
              <path d='M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z' />
            </svg>
          </button>
        </ModalHeader>
        <h4>Pools Available</h4>
        {pools.map((pool) => {
          const { token, address } = pool;
          return (
            <Header key={token.index} className="mb-4 w-100">
              <div>
                {currentExecute.length > 0 && !errorsHarvest.includes(pool.address) && !doneHarvest.includes(pool.address) &&
                  <span className="d-flex align-items-center oneRem me-1">{currentExecute.includes(pool.address) ? "Executing:" : "Waiting:"}</span>
                }
                {errorsHarvest.includes(pool.address) &&
                  <span className="d-flex align-items-center oneRem me-1">Error: </span>
                }
                {doneHarvest.includes(pool.address) &&
                  <span className="d-flex align-items-center oneRem me-1">Done: </span>
                }
              </div>
              <HeaderContent>
                <HeaderImageStyled.Pending>
                  <div className='name'>{formatNumber(formatValueWithoutCommaCero(pool.pendingRewards || '0', 18, 2), 2)}</div>
                  <TextSmall>LIZ</TextSmall>
                </HeaderImageStyled.Pending>
                <div className=''>
                  <TokenImage>
                    {token.isLp ? (
                      <>
                        {token.token0?.image && <img src={token.token0?.image} alt={token.token0.symbol} />}
                        {token.token1?.image && <img src={token.token1?.image} alt={token.token1.symbol} />}
                      </>
                    ) : (
                      token.image && <img src={token.image} alt={token.name} />
                    )}
                  </TokenImage>
                </div>
                <HeaderImageStyled.HeaderRight>
                  <div className='name'>{token.name}</div>
                </HeaderImageStyled.HeaderRight>
                <button onClick={() => {
                  setPools(prevPools => prevPools.filter(p => p.address !== address));
                }}>
                  <svg fill='#000000' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                    <path d='M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z' />
                  </svg>
                </button>
              </HeaderContent>
              {currentExecute.includes(pool.address) && !errorsHarvest.includes(pool.address) &&
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              }
            </Header>
          )
        })}

        <Button
          className='btn btn-dark w-100 mt-3'
          onClick={handleStart}
          disabled={currentExecute.length > 0}
        >
          Start
        </Button>
      </ModalContent>
      <ToastContainer />
    </Modal >
  )
}


export default ModalHarvestAll