import * as ethers from 'ethers'

export const formatValue = (value: string | number, decimals: number = 18): string => {
  if (value === '' || value === 0 || !value) return '0'
  const valueNumber = String(value).indexOf('.') !== -1 ? String(value).split('.')[0] : value
  return Number(ethers.utils.formatUnits(valueNumber, decimals))
    .toFixed(3)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const formatNumber = (value: string | number, decimals: number = 3): string => {
  if (value === '' || value === 0 || !value) return '0'
  if (value.toString().indexOf('NaN') !== -1) return '??'
  return Number(value)
    .toFixed(decimals)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const formatValueWithoutComma = (value: string | number, decimals: number = 18): string => {
  if (value === '' || value === 0 || !value) return ''
  const valueNumber = String(value).indexOf('.') !== -1 ? String(value).split('.')[0] : value
  return ethers.utils.formatUnits(valueNumber, decimals)
}

export const formatValueWithoutCommaCero = (
  value: string | number,
  decimals: number = 18,
  fixed?: number,
  exponential?: number
): string => {
  if (value === '' || value === 0 || !value || value === '0.0') return '0'
  const valueNumber = String(value).indexOf('.') !== -1 ? String(value).split('.')[0] : value
  if (exponential) {
    const number = Number(ethers.utils.formatUnits(valueNumber, decimals))
    if (number < 0.00001 && number !== 0) {
      return '<0.00001'
    }
  }
  if (!fixed) {
    return ethers.utils.formatUnits(valueNumber, decimals)
  }
  return Number(ethers.utils.formatUnits(valueNumber, decimals)).toFixed(fixed)
}

export const getIntegerBalance = (balance: number) => {
  return balance.toString().split('.')[0]
}

export const timeConverter = (UNIX_timestamp: number): string => {
  var a = new Date(UNIX_timestamp * 1000)
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  var year = a.getFullYear()
  var month = months[a.getMonth()]
  var date = a.getDate()
  var hour = a.getHours()
  var min = a.getMinutes()
  var sec = a.getSeconds()
  var time = month + ' ' + date + ', ' + year + ' ' + hour + ':' + min + ':' + sec
  return time
}

export const shorten = (str: string): string => {
  if (str.length < 10) return str
  return `${str.slice(0, 6)}...${str.slice(str.length - 4)}`
}
