import styled from 'styled-components'

interface WrapProps {
  isVisible?: boolean
}

export const Wrap = styled.div<WrapProps>`
  padding: 1rem;
`

export const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem;
  column-gap: 3rem;
  row-gap: .5rem;

  @media (max-width: 768px) {
    column-gap: 2rem;
  }
`

export const BodyButtons = styled.div`
  padding: 0 2rem 2rem;
  row-gap: .5rem;
  margin-top: auto;
  display: grid;
`

export const BodyItem = styled.div`
  // font-size: 0.8rem;
`
export const BodyItemTitle = styled.div`
  font-weight: bold;
`
export const BodyItemTitleBig = styled.div`
  font-weight: bold;
`

export const BodyItemValue = styled.div``
export const BodyItemValueBig = styled.div`
  font-size: 1.2rem;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 0 2rem;
  text-align: center;

  @media (max-width: 1200px) {
    grid-template-columns: 100px 1fr;

    .name {
      text-align: left;
    }
    .justify-content-start {
      justify-content: center !important;
      grid-column: 1 / -1;
    }
  }
`

export const TokenImage = styled.div`
  display: flex;
  justify-content: center;
  img {
      border-radius: 50%;
      height: 1.5rem;
      overflow: hidden;
      width: 1.5rem;

    &:last-child {
      height: 2rem;
      margin-top: 0.25rem;
      width: 2rem;
    }
  }
`

export const Card = styled.div`
  border: 1px solid #dddddd4a;
`

export const BtnLizard = styled.button`
  background-color: #1da094;
  width: 100%;
  display: block;
  grid-column: 1 / -1;
`

export const BtnLizard2 = styled.button`
  width: 100%;
  display: block;
  grid-column: 1 / -1;
  border: 1px solid #ddd !important;
`

export const ButtonsTrader = styled.div`
  display: flex;
  grid-column: 1 / -1;
  width: 100%;
  gap: .5rem;

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`
