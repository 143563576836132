import React, { Suspense, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useWeb3Context } from '../hooks'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { Routes } from './Routes'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {
  const { hasCachedProvider, connect } = useWeb3Context()
  useEffect(() => {
    if (hasCachedProvider()) {
      connect(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <LayoutProvider>
          <Routes />
        </LayoutProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
