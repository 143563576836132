import { useEffect, useState } from 'react'
import { useWeb3Context } from '../../../hooks'
import { addNetworkByChain } from '../../../utils/addNetworks'
import { defaultRPC, DEFAULT_NETWORK, lizardAddress } from '../../../utils/constants'
import { LpActions, PoolValues, TokensList, TokenStaking } from '../../../utils/farm'
import TokenStakeForm from '../../components/TokenStakeForm'
import './Farm.scss'
import TokenAutoCompounderForm from '../../components/TokenAutoCompounderForm'
import { ButtonGoBack } from '../../components/TokenStake.styled'
import LoaderMiniDetail from '../../components/LoaderMiniDetail'
import { initWeb3 } from '../../../hooks/web3/web3-context'
import LizardOriginalForm from '../../components/LizardOriginalForm'
import { formatNumber, formatValueWithoutCommaCero } from '../../../utils/format'
import BackArrow from '../../../assets/icons/arrow-left.svg'
import ModalHarvestAll from '../../modules/modals/ModalHarvestAll/ModalHarvestAll'

interface FarmProps {
  isLair: boolean
}

const Farm = ({ isLair }: FarmProps) => {
  const { connected, web3, address, providerChainID, hasCachedProvider } = useWeb3Context()
  const [tokenSelected, setTokenSelected] = useState<TokenStaking>()
  const [wrongNetwork, setWrongNetwork] = useState(true)
  const [poolsValues, setPoolsValues] = useState<any>({})
  const [lizardPriceUsd, setLizardPriceUsd] = useState<number>(0)
  const [isNotSelectedAToken, setIsNotSelectedAToken] = useState(true)
  const [farmsUsersList, setFarmsUsersList] = useState<string[]>([])
  const [onlyStaked, setOnlyStaked] = useState(false)
  const [showModal, setShowModal] = useState(false)

  let realPoolValues: any = {}

  const updatePool = async (token: TokenStaking, usdtLizardPrice?: number): Promise<void> => {
    try {
      if (!usdtLizardPrice) {
        usdtLizardPrice = await LpActions.getUSDTPriceOfToken(lizardAddress)
      }
      const _poolInfo = realPoolValues[token.address]
      let poolValues: PoolValues = {
        apr: '0.0',
        liquidity: '0.0',
        sharedPercentage: '0.0',
        pendingRewards: '0.0',
        bonusRewards: '0.0',
        tokenBalance: '0.0',
        tokenStaked: '0.0',
        address: token.address,
        token: token,
        isApproved: false,
        isSufficientBalance: false,
        actions: _poolInfo?.actions
          ? _poolInfo.actions
          : new LpActions(address, token, usdtLizardPrice),
        info: {
          0: '0.0',
          1: '0.0',
          2: '0.0',
          3: '0.0',
          4: '0.0',
          5: '0.0',
          accLizardPerShare: '0.0',
          allocPoint: '0.0',
          depositFeeBP: '0.0',
          lastRewardTime: '0.0',
          lpToken: '0.0',
          totalDeposit: '0.0',
        },
        totalStaked: '0.0',
        hasStaked: false,
        tvl: 0,
        lpPrice: 0,
        ...(_poolInfo ? _poolInfo : {}),
      }
      poolValues = await poolValues.actions.getPoolValues(poolValues)
      setFarmsUsersList((prevElements: string[]) => {
        const haveStaked = parseFloat(formatValueWithoutCommaCero(poolValues.tokenStaked || '0', 18)) > 0
        if (haveStaked && !prevElements.includes(token.index)) {
          return [...prevElements, token.index]
        } else {
          if (!haveStaked) {
            return prevElements.filter(element => element !== token.index)
          }
        }
        return prevElements
      })
      setPoolsValues((pools: any) => {
        realPoolValues = { ...realPoolValues, [token.address]: poolValues }
        return { ...pools, [token.address]: poolValues }
      })
    } catch (error) {
      console.log(error)
    }
  }


  const tokenList = TokensList[DEFAULT_NETWORK].slice(
    isLair ? 1 : 3, //2
    isLair ? 3 : TokensList[DEFAULT_NETWORK].length
  )

  useEffect(() => {
    if (Object.keys(web3).length > 0) {
      LpActions.web3 = web3
    } else {
      LpActions.web3 = initWeb3(defaultRPC)
    }
  }, [web3])

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined
    async function loadData() {
      const usdtLizardPrice = await LpActions.getUSDTPriceOfToken(lizardAddress)
      setLizardPriceUsd(usdtLizardPrice)
      tokenList.forEach((token) => updatePool(token, usdtLizardPrice))
      interval = setInterval(() => {
        tokenList.forEach((token) => updatePool(token, usdtLizardPrice))
      }, 60000)
    }
    if (
      Object.values(poolsValues).length === 0 &&
      Object.keys(LpActions.web3).length > 0 &&
      !interval &&
      connected &&
      address.length > 0
    ) {
      loadData()
    }

    if (
      !connected &&
      address.length === 0 &&
      Object.values(poolsValues).length === 0 &&
      Object.keys(LpActions.web3).length > 0 &&
      !hasCachedProvider()
    ) {
      loadData()
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, connected])

  useEffect(() => {
    if (connected) addNetworkByChain(web3, DEFAULT_NETWORK) // change network to oasis mainnet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected])

  useEffect(() => {
    setWrongNetwork(providerChainID !== DEFAULT_NETWORK)
  }, [providerChainID])

  let clase = "col-12 col-md-6 col-lg-6 col-xl-4"
  if (TokensList[DEFAULT_NETWORK][0].index === tokenSelected?.index) {
    clase = "col-md-12"
  }

  const poolValuesOfPool0 = poolsValues[TokensList[DEFAULT_NETWORK][1].address];

  const handleOnChangeOnlyStaked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyStaked(event.target.checked)
  }

  const calculateAllPendingRewards = () => {
    let pools: any = [];
    Object.keys(poolsValues).forEach(poolAddress => {
      if (farmsUsersList.includes(poolsValues[poolAddress].token.index) && !pools.some((pool: any) => pool.address === poolAddress)) {
        pools = [...pools, poolsValues[poolAddress]]
      }
    })

    let totalPendingRewards = 0;
    pools.forEach((pool: any) => {
      totalPendingRewards += Number(formatNumber(formatValueWithoutCommaCero(pool.pendingRewards || '0', 18, 2), 2))
    })

    return totalPendingRewards;
  }

  return (
    <div className='container mb-10'>
      <div className='row equal gy-5 g-xl-8 justify-content-md-center'>
        {tokenSelected && (
          <div className='col-12 card-form'>
            <ButtonGoBack
              type='button'
              className='btn'
              onClick={() => {
                setTokenSelected(undefined)
                setIsNotSelectedAToken(true)
              }}
            >
              <img src={BackArrow} />
            </ButtonGoBack>
          </div>
        )}


        {isNotSelectedAToken && !isLair && (
          <div className="col-md-12 d-flex row align-items-center justify-content-between">
            <div className='d-flex align-items-center col-md-6'>
              <label className="switch me-4">
                <input type="checkbox" checked={onlyStaked} onChange={handleOnChangeOnlyStaked} />
                <span className="slider round"></span>
              </label>
              Only Staked
            </div>
            {setFarmsUsersList.length > 0 &&
              (
                <div className='d-flex justify-content-end col-md-6 harvest-wrap'>
                  <div className='d-flex align-items-center justify-content-end w-100 mb-1 mt-1 me-5'>
                    Total pending: {formatNumber(calculateAllPendingRewards(), 2)} LIZ
                  </div>
                  <button className='btn btn-dark btn-sm ms-1 w-100' onClick={() => setShowModal(true)}>
                    Harvest all
                  </button>
                </div>
              )
            }
          </div>
        )}

        {isNotSelectedAToken && onlyStaked && farmsUsersList.length === 0 && (
          <div className="col-md-12">
            <div className="text-center" role="alert">
              No farms have staked yet.
            </div>
          </div>
        )}


        {isLair && isNotSelectedAToken && (
          poolValuesOfPool0 ? (
            <div className={`${clase} col-mini-detail`}>
              <TokenAutoCompounderForm
                token={TokensList[DEFAULT_NETWORK][0]}
                wrongNetwork={wrongNetwork}
                onSelectToken={(token: TokenStaking) => {
                  setTokenSelected(token)
                }}
                selected={TokensList[DEFAULT_NETWORK][0].index === tokenSelected?.index}
                lizardPriceUsd={lizardPriceUsd}
                poolValueOfPool0={poolValuesOfPool0}
              />
            </div>
          ) :
            (
              <div className={`${clase} col-mini-detail`}>
                <LoaderMiniDetail />
              </div>
            )
        )}

        {providerChainID &&
          tokenList.map((token, index) => {
            const poolValues = poolsValues[token.address]
            if (!(token.index === tokenSelected?.index) && tokenSelected) {
              return null;
            }
            clase = isLair ? (index === 0 ? "col-12 col-md-6 col-lg-6 col-xl-4" : "col-12 col-md-6 col-lg-6 col-xl-4") : "col-12 col-md-6 col-lg-6 col-xl-4";
            if (token.index === tokenSelected?.index) {
              clase = "col-md-12"
            }

            if (onlyStaked && !farmsUsersList.includes(token.index)) {
              return null;
            }
            return (
              <div
                className={`${clase} col-mini-detail`}
                key={token.index}
              >
                {!poolValues ? (
                  <LoaderMiniDetail />
                ) : (
                  <TokenStakeForm
                    token={token}
                    poolValues={poolValues}
                    updatePool={updatePool}
                    wrongNetwork={wrongNetwork}
                    onSelectToken={(token: TokenStaking) => {
                      setIsNotSelectedAToken(false)
                      setTokenSelected(token)
                    }}
                    selected={token.index === tokenSelected?.index}
                  />
                )}
              </div>
            )
          })}

        {providerChainID && isLair && tokenList[1] && connected && isNotSelectedAToken && (
          <LizardOriginalForm token={tokenList[1]} />
        )}
        {showModal && <ModalHarvestAll
          showModal={showModal}
          closeModal={() => setShowModal(false)}
          farmsUsersList={farmsUsersList}
          poolsValues={poolsValues}
        />}
      </div>
    </div>
  )
}

export default Farm
