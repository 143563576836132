import { FC } from 'react'
import { PoolValuesAutoCompounder } from '../../utils/autocompounder'
import { lizardAddress } from '../../utils/constants'
import { TokenStaking } from '../../utils/farm'
import { formatNumber } from '../../utils/format'
import HeaderImageTokenAutoCompounder from './HeaderImageTokenAutoCompounder'
import { Body, BodyButtons, BtnLizard, BtnLizard2, ButtonsTrader, Card } from './TokenMiniDetail.styled'

import { BodyItem, BodyItemTitle, BodyItemValue, TextSmall } from './TokenStake.styled'

interface TokenMiniDetailProps {
  token: TokenStaking
  onSelect: (token: TokenStaking) => void
  poolValues: PoolValuesAutoCompounder
}

const lizardPoolIndex = "0"
const lhodlPoolIndex = "8"

const nativePoolsIndexes = [lizardPoolIndex, lhodlPoolIndex]


const TokenMiniDetailAutoCompounder: FC<TokenMiniDetailProps> = ({ token, onSelect, poolValues }) => {
  const handleOpenToStake = () => {
    onSelect(token)
  }

  const handleTrade = () => {
    let url = `https://app.lizard.exchange/swap?inputCurrency=${token.token0Address}&outputCurrency=${token.token1Address}`
    if (token.index === lizardPoolIndex) {
      url = `https://app.lizard.exchange/swap?inputCurrency=ROSE&outputCurrency=${token.address}`
    }
    window.open(
      url,
      '_blank'
    )
  }
  const handleBuyLiz = () => {
    let url = `https://app.lizard.exchange/swap?inputCurrency=ROSE&outputCurrency=${lizardAddress}`
    window.open(
      url,
      '_blank'
    )
  }

  const handleAddLiquidity = () => {
    window.open(
      `https://app.lizard.exchange/add/${token.token0Address}/${token.token1Address}`,
      '_blank'
    )
  }

  return (
    <Card className='card h-100'>
      <HeaderImageTokenAutoCompounder token={token} ratio={poolValues.ratio} />
      <Body>
        <BodyItem>
          <BodyItemTitle>BALANCE</BodyItemTitle>
          <BodyItemValue>
            {formatNumber(poolValues.xLizardBalance, 2)}
            <TextSmall>{token.autoCompounderSymbol}</TextSmall>
          </BodyItemValue>
        </BodyItem>
        <BodyItem>
          <BodyItemTitle>APY</BodyItemTitle>
          <BodyItemValue>{formatNumber(poolValues.apy, 1)}%</BodyItemValue>
        </BodyItem>
        <BodyItem>
          <BodyItemTitle>YOUR STAKE</BodyItemTitle>
          <BodyItemValue>
            {formatNumber(poolValues.xLizardValueInLiz, 2)}
            <TextSmall>{token.symbol}</TextSmall>
          </BodyItemValue>
        </BodyItem>
      </Body>
      <BodyButtons>
        <BtnLizard2 className='btn w-100' onClick={handleOpenToStake}>
          AutoStake $LIZ
        </BtnLizard2>
        {(token.isLp || nativePoolsIndexes.includes(token.index)) && (
          <ButtonsTrader>
            {!nativePoolsIndexes.includes(token.index) && (<BtnLizard
              className='btn btn-dark w-100'
              onClick={handleAddLiquidity}
            >
              + Liquidity
            </BtnLizard>)}
            <BtnLizard
              className='btn btn-dark w-100'
              onClick={handleTrade}
            >
              Trade
            </BtnLizard>
          </ButtonsTrader>
        )}
        {token.isAutoCompounder && (
          <BtnLizard
            className='btn btn-dark w-100'
            onClick={handleBuyLiz}
          >
            Buy $LIZ
          </BtnLizard>)}
      </BodyButtons>
    </Card>
  )
}

export default TokenMiniDetailAutoCompounder
