import styled from 'styled-components'

const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;

  .name {
    font-size: 1.25rem;
    text-align: right;
  }
`

const Pending = styled.div`
  display: flex;
  align-items: center; 
  justify-content: center;
`

export const HeaderImageStyled = {
  HeaderRight,
  Pending
}