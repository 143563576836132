import React, {useState, ReactElement, useContext, useMemo, useCallback} from 'react'
import Web3Modal from 'web3modal'
import {JsonRpcProvider, Web3Provider} from '@ethersproject/providers'
import WalletConnectProvider from '@walletconnect/web3-provider'
import {getMainnetURI, getTestnetURI} from './helpers'
import {messages} from '../../constants/messages'
import {swithNetwork} from '../../utils/switch-network'
import Web3 from 'web3'
import {Network, DEFAULT_NETWORK} from '../../utils/constants'

type onChainProvider = {
  connect: (connect?: boolean) => Promise<Web3Provider>
  disconnect: () => void
  checkWrongNetwork: () => Promise<boolean>
  provider: JsonRpcProvider
  address: string
  connected: boolean
  web3Modal: Web3Modal
  web3?: any
  providerChainID: number
  chainID: number
  hasCachedProvider: () => boolean
}

export type Web3ContextData = {
  onChainProvider: onChainProvider
} | null

export function initWeb3(provider: any) {
  const web3: any = new Web3(provider)

  web3.eth.extend({
    methods: [
      {
        name: 'chainId',
        call: 'eth_chainId',
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  })

  return web3
}

const Web3Context = React.createContext<Web3ContextData>(null)

export const useWeb3Context = () => {
  const web3Context = useContext(Web3Context)
  if (!web3Context) {
    throw new Error(
      'useWeb3Context() can only be used inside of <Web3ContextProvider />, ' +
        'please declare it at a higher level.'
    )
  }
  const {onChainProvider} = web3Context
  return useMemo(() => {
    return {...onChainProvider}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3Context])
}

export const useAddress = () => {
  const {address} = useWeb3Context()
  return address
}

export const Web3ContextProvider: React.FC<{children: ReactElement}> = ({children}) => {
  const [connected, setConnected] = useState(false)
  const [chainID, setChainID] = useState(DEFAULT_NETWORK)
  const [providerChainID, setProviderChainID] = useState(DEFAULT_NETWORK)
  const [address, setAddress] = useState('')
  const [web3, setWeb3] = useState<any>({})

  const [provider, setProvider] = useState<JsonRpcProvider>()

  const [web3Modal] = useState<Web3Modal>(
    new Web3Modal({
      cacheProvider: true,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              [Network.OASIS]: getMainnetURI(),
              [Network.OASIS_TESTNET]: getTestnetURI(),
            },
          },
        },
      },
    })
  )

  const hasCachedProvider = (): boolean => {
    if (!web3Modal) return false
    if (!web3Modal.cachedProvider) return false
    return true
  }

  const _initListeners = useCallback((rawProvider: JsonRpcProvider) => {
    if (!rawProvider.on) {
      return
    }

    rawProvider.on('accountsChanged', () => setTimeout(() => window.location.reload(), 1))

    rawProvider.on('chainChanged', async (chain: number) => {
      changeNetwork(chain)
      setTimeout(() => window.location.reload(), 1)
    })

    rawProvider.on('network', (_newNetwork, oldNetwork) => {
      if (!oldNetwork) return
      window.location.reload()
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeNetwork = async (otherChainID: number) => {
    const network = Number(otherChainID)

    setProviderChainID(network)
  }

  const connect = useCallback(
    async (reload) => {
      const rawProvider = await web3Modal.connect()
      if (reload) {
        setTimeout(() => window.location.reload(), 1)
      }
      setWeb3(initWeb3(rawProvider))
      _initListeners(rawProvider)

      const connectedProvider = new Web3Provider(rawProvider, 'any')

      const chainId = await connectedProvider
        .getNetwork()
        .then((network) => Number(network.chainId))
      setChainID(chainId)
      const connectedAddress = await connectedProvider.getSigner().getAddress()

      setAddress(connectedAddress)

      setProviderChainID(chainId)

      if (chainId === DEFAULT_NETWORK) {
        setProvider(connectedProvider)
      }

      setConnected(true)

      return connectedProvider
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [provider, web3Modal, connected]
  )

  const checkWrongNetwork = async (): Promise<boolean> => {
    if (providerChainID !== DEFAULT_NETWORK) {
      const shouldSwitch = window.confirm(messages.switch_to_avalanche)
      if (shouldSwitch) {
        await swithNetwork()
        window.location.reload()
      }
      return true
    }

    return false
  }

  const disconnect = useCallback(
    async () => {
      web3Modal.clearCachedProvider()
      setConnected(false)

      setTimeout(() => {
        window.location.reload()
      }, 1)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [provider, web3Modal, connected]
  )

  const onChainProvider = useMemo(
    () => ({
      connect,
      disconnect,
      hasCachedProvider,
      provider,
      connected,
      address,
      chainID,
      web3Modal,
      providerChainID,
      checkWrongNetwork,
      web3,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      connect,
      disconnect,
      hasCachedProvider,
      provider,
      connected,
      address,
      web3Modal,
      providerChainID,
      web3,
      chainID,
    ]
  )
  //@ts-ignore
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return <Web3Context.Provider value={{onChainProvider}}>{children}</Web3Context.Provider>
}
