import clsx from 'clsx'
import {Link} from 'react-router-dom'
import ConnectMenu from '../../../../utils/connect-button'
import {toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import throttle from "lodash.throttle";
import styled from 'styled-components'
import { useState, useRef, useEffect } from 'react'
import { Palette, Spacing } from 'css-in-js'
import useWindowSize from 'hooks/useWindowSize'
import { Button, Drawer, Menu, Typography } from 'antd'
import { MenuOutlined } from '@ant-design/icons';
import { Routes } from 'utils/routes'

const { Title } = Typography;

export const MENU_HEIGHT = 69;

const Connect = styled.div`
  @media (max-width: 1024px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: ${Spacing.Spacing1} 0;
  }
`

const HeaderInner = styled.div<{ showMenu: boolean }>`
  background-color: rgba(33, 54, 61, 1) !important;
  box-shadow: ${({ showMenu }) => (showMenu ? 'rgb(4 17 29 / 25%) 0px 0px 5px 0px' : `rgb(4 17 29 / 25%) 0px 0px 0px 0px`)};
  height: ${MENU_HEIGHT}px;
  position: fixed;
  top: ${({ showMenu }) => (showMenu ? 0 : `-${MENU_HEIGHT}px`)};
  transform: translate3d(0, 0, 0);
  transition: top 0.2s;
  width: 100%;
  z-index: 20;
`

const HamburgerButton = styled.div`
  align-items: center;
  display: flex;
  padding-left: 0;
`;

const MainDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper { 
    max-width: 80% !important;

    .ant-drawer-content {
      background-color: ${Palette.LizardBackground};
    }
  }
  .ant-drawer-header {
    background-color: ${Palette.LizardBackground};
    height: ${MENU_HEIGHT}px;
    padding: 0;
  }

  .ant-drawer-title {
    display: flex;
  }

  .ant-drawer-body {
    padding: 0;

    .ant-menu {
      background-color: ${Palette.LizardBackground};
    }
  }
`

export function HeaderWrapper() {
  const { isLtLg } = useWindowSize();
  const {classes, attributes} = useLayout()
  const [showMenu, setShowMenu] = useState(true);
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const refPrevOffset = useRef(window.pageYOffset);

  const clickMobileItem = () => {
    setShowMenuMobile(false)
  }
  
  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
      const isTopOfPage = currentOffset === 0;

      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true);
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current) {
          // Has scroll up
          setShowMenu(true);
        } else {
          // Has scroll down
          setShowMenu(false);
        }
      }
      refPrevOffset.current = currentOffset;
    };

    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);

  return (
    <>
      <HeaderInner
        showMenu={showMenu}
        className={clsx('header', classes.header.join(' '), 'd-flex align-items-center')}
        {...attributes.headerMenu}
        style={{marginBottom: '1px solid rgba(0, 0, 0, 0.1)'}}
      >
        {/* begin::Wrapper */}
        <div className='d-flex align-items-center px-6 justify-content-between flex-lg-grow-1 w-100'>
          <div className='d-flex align-items-stretch w-100'>
            <div className='header-logo me-5 flex-grow-1 flex-lg-grow-0'>
              <Link to='/' className='d-flex align-items-center'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logo.svg')}
                  className='logo-default h-md-50px h-40px me-0 me-md-3'
                />
                {isLtLg && (
                  <span className='ms-4' style={{ color: '#FFF' }}>Lizard</span>
                )}
              </Link>
            </div>
            {isLtLg && (
              <>
                <HamburgerButton className='ms-auto'>
                  <Button type="ghost" shape="circle" icon={<MenuOutlined style={{ color: '#FFF' }} />} onClick={() => setShowMenuMobile(true)} />
                </HamburgerButton>
                <MainDrawer
                    title={
                      <Title level={5} className="mb-0 p-3">
                        <img
                          alt='Logo'
                          src={toAbsoluteUrl('/media/logo.svg')}
                          className='logo-default h-30px me-3 me-md-3'
                        />
                        <span style={{ color: '#FFF' }}>Lizard</span>
                      </Title>
                    }
                    placement="left"
                    closable={false}
                    onClose={() => setShowMenuMobile(false)}
                    visible={showMenuMobile}
                  >
                    <Menu theme="dark" mode="inline">
                      <Menu.Item key="1">
                        <a href={Routes.Swap} onClick={() => clickMobileItem()} className="text-uppercase">
                          Swap
                        </a>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <a href={Routes.Pool} onClick={() => clickMobileItem()} className="text-uppercase">
                          Pool
                        </a>
                      </Menu.Item>
                      <Menu.Item key="3">
                        <a href={Routes.Farm} onClick={() => clickMobileItem()} className="text-uppercase">
                          Farm
                        </a>
                      </Menu.Item>
                      <Menu.Item key="4">
                        <a href={Routes.Lair} onClick={() => clickMobileItem()} className="text-uppercase">
                          Lair
                        </a>
                      </Menu.Item>
                      <Menu.Item key="5">
                        <a href={Routes.Koth} onClick={() => clickMobileItem()} className="text-uppercase">
                          King of the hill
                        </a>
                      </Menu.Item>
                      <Menu.Item key="6">
                        <a href={Routes.Info} target={'_blank'} onClick={() => clickMobileItem()} className="text-uppercase">
                          Charts
                        </a>
                      </Menu.Item>
                    </Menu>
                  </MainDrawer>
              </>
            )}
            {!isLtLg && (
              <Header />
            )}
          </div>
          {!isLtLg && (
            <Connect className='d-flex align-items-stretch flex-shrink-0'>
              {<ConnectMenu reload />}
            </Connect>
          )}
        </div>
        {/* end::Wrapper */}

        
      </HeaderInner>
      {isLtLg && (
        <Connect className='d-flex align-items-stretch'>
          {<ConnectMenu reload />}
        </Connect>
      )}
    </>
  )
}
