import { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { formatValueWithoutComma, formatValueWithoutCommaCero } from '../../utils/format'
import { InputAmount, Button, TextSmall } from './LizardOriginalForm.styled'
import BlockUi from '@availity/block-ui'
import { TokenStaking } from '../../utils/farm'
import { OriginalsHolders } from '../../utils/original-holders'
import { useWeb3Context } from '../../hooks'

interface LizardOriginalFormProps {
  token: TokenStaking
}

const LizardOriginalForm = ({ token }: LizardOriginalFormProps) => {
  const [amount, setAmount] = useState('')
  const [disabledForm, setDisabledForm] = useState(false)
  const { web3, address } = useWeb3Context()
  const [amountAvailable, setAmountAvailable] = useState('0')
  const [amountLizard, setAmountLizard] = useState('0')
  const [lizardApproved, setLizardApproved] = useState(false)
  const [insufficientLizardAmount, setInsufficientLizardAmount] = useState(true)
  const [amountOriginals, setAmountOriginals] = useState('0')

  const loadLizardAmount = async () => {
    const amountOfLizard = await OriginalsHolders.getBalanceOfLizard()
    setAmountLizard(amountOfLizard)
    setInsufficientLizardAmount(Number(formatValueWithoutCommaCero(amountOfLizard, 18)) === 0)
  }
  const loadOriginalsAmount = async () => {
    const amount = await OriginalsHolders.getBalanceOfOriginals()
    setAmountOriginals(amount)
  }

  const loadAmountAvailable = async () => {
    const amountAvailable = await OriginalsHolders.getAvailableAmount()
    setAmountAvailable(amountAvailable)
  }

  const loadApproved = async () => {
    const amountAllowed = await OriginalsHolders.getAllowedAmount()
    setLizardApproved(Number(formatValueWithoutCommaCero(amountAllowed, 18)) > 0)
  }

  const loadBalances = async () => {
    OriginalsHolders.init(web3, address, token)
    loadApproved()
    loadLizardAmount()
    loadAmountAvailable()
    loadOriginalsAmount()
  }

  useEffect(() => {
    if (Object.keys(web3).length !== 0 && address) {
      loadBalances()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3, address])

  const isInvalidAmount = (): boolean => {
    if (amount === '') {
      return true
    }
    const amountNumber = Number(amount)
    if (isNaN(amountNumber)) {
      return true
    }
    if (amountNumber <= 0) {
      return true
    }
    return false
  }

  const handleWrap = async () => {
    if (isInvalidAmount()) {
      toast.error('Invalid amount')
      return
    }
    try {
      toast('Sending transaction...', {
        autoClose: false,
        toastId: 'deposit',
        type: toast.TYPE.INFO,
      })
      setDisabledForm(true)
      await OriginalsHolders.redeem(amount)
      toast.update('deposit', {
        render: 'Deposit completed',
        autoClose: 4000,
        type: toast.TYPE.SUCCESS,
      })
      setAmount('')
      setDisabledForm(false)
      loadBalances()
    } catch (error: any) {
      setDisabledForm(false)
      toast.update('deposit', {
        render: error,
        autoClose: 4000,
        type: toast.TYPE.ERROR,
      })
    }
  }

  const handleUnwrap = async () => {
    if (isInvalidAmount()) {
      toast.error('Invalid amount')
      return
    }
    try {
      setDisabledForm(true)
      toast('Sending transaction...', {
        autoClose: false,
        toastId: 'withdraw',
        type: toast.TYPE.INFO,
      })
      await OriginalsHolders.returnTokens(amount)
      setAmount('')
      toast.update('withdraw', {
        render: 'Withdraw completed',
        autoClose: 4000,
        type: toast.TYPE.SUCCESS,
      })
      setDisabledForm(false)
      loadBalances()
    } catch (error: any) {
      setDisabledForm(false)
      toast.update('withdraw', {
        render: error,
        autoClose: 4000,
        type: toast.TYPE.ERROR,
      })
    }
  }

  const handleApproveToken = async () => {
    try {
      setDisabledForm(true)
      toast('Sending transaction...', {
        autoClose: false,
        toastId: 'approve',
        type: toast.TYPE.INFO,
      })
      await OriginalsHolders.approveToken()
      toast('Token approved...', {
        autoClose: false,
        toastId: 'approve',
        type: toast.TYPE.SUCCESS,
      })
      setDisabledForm(false)
      loadBalances()
    } catch (error: any) {
      setDisabledForm(false)
      toast.update('approve', {
        render: error,
        autoClose: 4000,
        type: toast.TYPE.ERROR,
      })
    }
  }

  const dontHasToRedeem = parseFloat(formatValueWithoutCommaCero(amountAvailable || '0', 18)) < 0.01
  const dontHasToUnwrap = parseFloat(formatValueWithoutCommaCero(amountOriginals || '0', 18)) < 0.01

  if (dontHasToRedeem && dontHasToUnwrap) {
    return null;
  }

  return (
    <div className='col-md-6 offset-md-1 ms-md-auto col-mini-detail'>
      <BlockUi blocking={disabledForm}>
        <div className='card p-5 w-100'>
          <h3 className='text-center mb-6'>Wrap your Lizard for a special pool</h3>
          <div className='d-flex justify-content-between w-100 pb-2'>
            <span>AVAILABLE IN WALLET</span>
            <span>
              {formatValueWithoutCommaCero(amountLizard || '0', 18, 2, 2)}
              <TextSmall>LIZ</TextSmall>
            </span>
          </div>
          <div className='d-flex justify-content-between w-100 pb-2'>
            <span>AMOUNT OF LIZARD ORIGINAL HOLDERS TOKEN</span>
            <span>
              {formatValueWithoutCommaCero(amountOriginals || '0', 18, 2, 2)}
              <TextSmall>{token.symbol}</TextSmall>
            </span>
          </div>
          <div className='d-flex justify-content-between w-100 pb-2'>
            <span>AMOUNT OF ${token.symbol} TO REDEEM</span>
            <span>
              {formatValueWithoutCommaCero(amountAvailable || '0', 18, 2)}
              <TextSmall>{token.symbol}</TextSmall>
            </span>
          </div>
          <br></br>
          <InputAmount>
            <input
              type='number'
              inputMode='decimal'
              autoComplete='off'
              value={amount}
              className='form-control'
              aria-label='Token Amount'
              title='Token Amount'
              autoCorrect='off'
              pattern='^[0-9]*[.,]?[0-9]*$'
              placeholder='0.0'
              minLength={1}
              maxLength={79}
              spellCheck={false}
              onChange={(e) => {
                setAmount(e.target.value)
              }}
            />
            <span className='d-flex gap-1'>
              <button
                className='btn btn-dark'
                onClick={() => {
                  setAmount(formatValueWithoutComma(amountLizard))
                }}
              >
                MAX $LIZ
              </button>
              <button
                className='btn btn-dark'
                onClick={() => {
                  setAmount(formatValueWithoutComma(amountOriginals))
                }}
              >
                MAX ${token.symbol}
              </button>
            </span>
          </InputAmount>

          {lizardApproved ? (
            <>
              <Button
                className='btn btn-dark'
                disabled={insufficientLizardAmount || dontHasToRedeem}
                onClick={() => handleWrap()}
              >
                {insufficientLizardAmount ? 'Insufficient amount' : 'Wrap $LIZ to $LHODL'}
              </Button>
              <Button
                disabled={dontHasToUnwrap}
                className='btn btn-dark'
                onClick={() => handleUnwrap()}
              >
                Unwrap $LHODL to $LIZ
              </Button>
            </>
          ) : (
            <Button className='btn btn-dark' onClick={() => handleApproveToken()}>
              Approve {token.symbol}
            </Button>
          )}

          <span className='mt-5 text-center'>Warning ⚠️</span>
          <span className='text-center'>If you unwrap, you won't be able to redeem again.</span>

          <ToastContainer />
        </div>
      </BlockUi>
    </div>
  )
}

export default LizardOriginalForm
