import styled from 'styled-components'

export const BoxWrap = styled.div`
  display: grid;
  grid-template-columns: 30rem 1fr;
  gap: 2rem;
  width: 100%;

  @media (max-width: 768px) {
    gap: 0.5rem;
    grid-template-columns: 1fr;
  }
`

export const Box = styled.div`
  padding: 1rem;
  width: 100%;
`

export const BoxSpace = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Tabs = styled.ul`
  margin-bottom: 1rem;
  li {
    cursor: pointer;
    .nav-link {
      color: white !important;
    }
  }
`

export const Balances = styled.div`
  display: flex;
  justify-content: space-between;
`

export const BalancesText = styled.div`
  font-size: 0.8rem;
`

export const InputAmount = styled.div`
  display: grid;
  grid-template-columns: 1fr 174px;
  background: rgba(0, 0, 0, 0.4);
  padding: 10px;
  border-radius: 10px;
  width: 100%;

  input {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1.2rem;
    padding: 0;
    width: 100%;
    &:focus {
      outline: none;
      background: transparent;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  span > button {
    background-color: rgba(255, 255, 255, 0.5);
    background-image: none;
    padding: 5px !important;
    font-size: 14px;
  }
`

export const Button = styled.button`
  background-color: rgba(255, 255, 255, 0.5);
  background-image: none;

  width: 100%;
  margin-top: 1rem;

  background-color: ${(props) =>
    props.disabled ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.9)'};
`

export const ButtonGoBack = styled.button`
  display: inline;
  background-color: rgba(255, 255, 255, 0);
  background-image: none;
  text-align: left;
  padding: 0;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

export const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const BodyVertical = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  margin-top: 20px;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
  }
`

export const BodyItem = styled.div``
export const BodyItemTitle = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
`
export const BodyItemValue = styled.div`
  font-size: 1.5rem;
`
export const HarvestWrap = styled.div`
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  grid-template-columns: 1fr 10rem;
  align-items: center;
  padding: 1rem;
  margin: 1rem 0;
  button {
    display: block;
  }
`
export const Horizontal = styled.div`
  display: grid;
  grid-template-columns: 10rem 1fr;
  grid-template-rows: 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const TextSmall = styled.span`
  font-size: 0.9rem;
  margin-left: 6px;
`

export const TextPriceLP = styled.div`
  font-size: 0.8rem;
  margin-left: 6px;
`

export const ModalContent = styled.div`
  padding: 1rem;
  background: #212429;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  min-width: 400px;
`

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  button {
    background: transparent;
    border: none;
    margin-bottom: 10px;
    svg {
      fill: white;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`
