import { TokenStaking } from '../../utils/farm'
import { formatValueWithoutCommaCero } from '../../utils/format'
import { HeaderImageStyled } from './HeaderImageToken.styled'
import { Header, TokenImage } from './TokenMiniDetail.styled'

interface TokenStakeFormProps {
  token: TokenStaking
  allocPoint: string
}

const HeaderImageToken = ({ token, allocPoint }: TokenStakeFormProps) => {
  return (
    <Header>
      <div className='d-flex justify-content-end'>
        <TokenImage>
          {token.isLp ? (
            <>
              {token.token0?.image && <img src={token.token0?.image} alt={token.token0.symbol} />}
              {token.token1?.image && <img src={token.token1?.image} alt={token.token1.symbol} />}
            </>
          ) : (
            token.image && <img src={token.image} alt={token.name} />
          )}
        </TokenImage>
      </div>
      <HeaderImageStyled.HeaderRight>
        <div className='name'>{token.name}</div>
        <div className='d-flex justify-content-end'>
          <span className='badge badge-secondary'>x{formatValueWithoutCommaCero(allocPoint, 0)}</span>
        </div>
      </HeaderImageStyled.HeaderRight>
    </Header>
  )
}

export default HeaderImageToken
