import { getRosePrice, getBulkPairDataNoGraph } from './rpc-requests'
import { pack, keccak256 } from '@ethersproject/solidity'
import { getCreate2Address } from '@ethersproject/address'
import { FACTORY_ADDRESS, wRoseAddress } from './constants'

export interface Pair {
  id: string
  name: string
  token0: PairToken
  token1: PairToken
}

export interface PairToken {
  id: string
  name: string
  symbol: string
}

export interface TokenData {
  id: string
  name: string
  symbol: string
  derivedETH: number
  tradeVolume: number
  tradeVolumeUSD: number
  untrackedVolumeUSD: number
  totalLiquidity: number
  txCount: number
}

export interface TokenResponse {
  address: string
  name: string
  symbol: string
  currentPrice: number
  totalLiquidity: number
  volumePrice: number
  volumePriceETH: number
  volumeChange24h: number
  priceChangePercentage24h: number
  oneDayVolumeUT: number
  volumeChangeUT: number
  liquidityChange: number
  txnChange: number
  oneDayData: number
  twoDayData: number
  oneDayTxns: number
}

export function formattedPercent(percent: number, useBrackets = false): string {
  if (!percent || percent === 0) {
    return '0%'
  }

  if (percent < 0.0001 && percent > 0) {
    return '< 0.0001%'
  }

  if (percent < 0 && percent > -0.0001) {
    return '< 0.0001%'
  }

  let fixedPercent = percent.toFixed(2)
  if (fixedPercent === '0.00') {
    return '0%'
  }
  if (percent > 0) {
    if (percent > 100) {
      return percent?.toFixed(0).toLocaleString()
    } else {
      return fixedPercent
    }
  } else {
    return fixedPercent
  }
}

export async function getPair(pairAddress: string) {
  const [newPrice] = await getRosePrice()
  const pairs = await getBulkPairDataNoGraph([pairAddress], newPrice)
  if (pairs) {
    return pairs[0]
  } else {
    return null
  }
}

let PAIR_ADDRESS_CACHE: { [token0Address: string]: { [token1Address: string]: string } } = {}

interface Token {
  address: string
  sortsBefore: (other: Token) => boolean
}

function getAddress(tokenA: Token, tokenB: Token): string {
  const tokens = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks

  if (PAIR_ADDRESS_CACHE?.[tokens[0].address]?.[tokens[1].address] === undefined) {
    PAIR_ADDRESS_CACHE = {
      ...PAIR_ADDRESS_CACHE,
      [tokens[0].address]: {
        ...PAIR_ADDRESS_CACHE?.[tokens[0].address],
        [tokens[1].address]: getCreate2Address(
          FACTORY_ADDRESS,
          keccak256(['bytes'], [pack(['address', 'address'], [tokens[0].address, tokens[1].address])]),
          "0x9710a00dcd1c16cb7edda1d9280ccc60072663dee473726eaffc306fef2b0b4a"
        )
      }
    }
  }

  return PAIR_ADDRESS_CACHE[tokens[0].address][tokens[1].address]
}

export async function getTokenByAddress(tokenAddress: string) {
  const [newPrice, oneDayPrice] = await getRosePrice()
  const pairAddress = getAddress({
    address: tokenAddress,
    sortsBefore(other: Token): boolean {
      return this.address.toLowerCase() < other.address.toLowerCase()
    }
  }, {
    address: wRoseAddress,
    sortsBefore(other: Token): boolean {
      return this.address.toLowerCase() < other.address.toLowerCase()
    }
  })

  const pairData = await getBulkPairDataNoGraph([pairAddress], newPrice);
  if (pairData.length > 0) {
    const pair = pairData[0]
    return pair;
  }
  return null;
}
