
import styled from "styled-components";

export const ModalContent = styled.div`
  padding: 1rem;
  background: #212429;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  min-width: 375px;
`

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  button {
    background: transparent;
    border: none;
    margin-bottom: 10px;
    svg {
      fill: white;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`

export const HeaderContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 20px;
  width: 100%;
  justify-content: center;

  button {
    background: transparent;
    border: none;
    margin-top: -10px;
    svg {
      fill: white;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`
