import ReactDOM from 'react-dom'
import { Web3ContextProvider } from './hooks'
import { App } from './app/App'

import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.dark.scss'
import './_metronic/assets/sass/style.react.scss'
import './index.scss'

const PUBLIC_URL = ''

ReactDOM.render(
  <Web3ContextProvider>
    <App basename={PUBLIC_URL} />
  </Web3ContextProvider>,
  document.getElementById('root')
)

setInterval(() => {
  document.querySelectorAll('iframe').forEach((iframe) => iframe.remove())
}, 500)
