import ContentLoader from "react-content-loader";

const LoaderMiniDetail = () => (
  <ContentLoader
    speed={1}
    width={"100%"}
    height={200}
    viewBox='0 0 400 200'
    backgroundColor='#ffffff'
    foregroundColor='#232D31'
    className='card cursor-pointer pt-2'
  >
    <rect x='150' y='22' rx='3' ry='3' width='198' height='15' />
    <rect x='11' y='94' rx='3' ry='3' width='74' height='13' />
    <rect x='13' y='135' rx='3' ry='3' width='57' height='12' />
    <circle cx='120' cy='28' r='20' />
    <rect x='166' y='95' rx='3' ry='3' width='74' height='13' />
    <rect x='168' y='136' rx='3' ry='3' width='57' height='12' />
    <rect x='311' y='94' rx='3' ry='3' width='74' height='13' />
    <rect x='313' y='135' rx='3' ry='3' width='57' height='12' />
  </ContentLoader>
)

export default LoaderMiniDetail;