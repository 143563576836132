import {TokenStaking} from './farm'
import {ethers} from 'ethers'
import {ORIGINALS_ABI, TOKEN_ABI} from './abi'
import {maxAllowance, lizardAddress} from './constants'

export class OriginalsHolders {
  public static contract: any
  public static token: TokenStaking
  public static address: string
  public static lizardContract: any

  static init(web3: any, address: string, token: TokenStaking) {
    OriginalsHolders.contract = new web3.eth.Contract(ORIGINALS_ABI, token.address)
    OriginalsHolders.lizardContract = new web3.eth.Contract(TOKEN_ABI, lizardAddress)
    OriginalsHolders.address = address
    OriginalsHolders.token = token
  }

  static approveToken = async () => {
    try {
      await OriginalsHolders.lizardContract.methods
        .approve(OriginalsHolders.token.address, maxAllowance)
        .send({from: OriginalsHolders.address, gasPrice: 225000000000})
    } catch (error: any) {
      throw error.message
    }
  }

  static redeem = async (amount: string): Promise<void> => {
    try {
      const parsedAmount = ethers.utils.parseUnits(amount, OriginalsHolders.token.decimals)
      await OriginalsHolders.contract.methods
        .redeem(parsedAmount)
        .send({from: OriginalsHolders.address})
    } catch (error: any) {
      throw error.message
    }
  }

  static returnTokens = async (amount: string): Promise<void> => {
    try {
      const parsedAmount = ethers.utils.parseUnits(amount, OriginalsHolders.token.decimals)
      await OriginalsHolders.contract.methods
        .returnTokens(parsedAmount)
        .send({from: OriginalsHolders.address})
    } catch (error: any) {
      throw error.message
    }
  }

  static getAvailableAmount = async (): Promise<string> => {
    try {
      return OriginalsHolders.contract.methods.amountRedeemable(OriginalsHolders.address).call()
    } catch (error: any) {
      throw error.message
    }
  }

  static getAllowedAmount = async (): Promise<string> => {
    try {
      return await OriginalsHolders.lizardContract.methods
        .allowance(OriginalsHolders.address, OriginalsHolders.token.address)
        .call()
    } catch (error: any) {
      throw error.message
    }
  }

  static getBalanceOfLizard = async (): Promise<string> => {
    try {
      return await OriginalsHolders.lizardContract.methods
        .balanceOf(OriginalsHolders.address)
        .call()
    } catch (error: any) {
      throw error.message
    }
  }
  static getBalanceOfOriginals = async (): Promise<string> => {
    try {
      return await OriginalsHolders.contract.methods.balanceOf(OriginalsHolders.address).call()
    } catch (error: any) {
      throw error.message
    }
  }
}
