import { useState, FC, useEffect } from 'react'
import { PoolValues, TokenStaking } from '../../utils/farm'
import { formatValueWithoutComma, formatValueWithoutCommaCero, formatNumber } from '../../utils/format'
import 'react-toastify/dist/ReactToastify.css'
import {
  Box,
  BoxWrap,
  Balances,
  InputAmount,
  Button,
  Body,
  BodyItem,
  BodyItemTitle,
  BodyItemValue,
  BoxSpace,
  TextSmall,
  ModalContent,
  ModalHeader,
  TextPriceLP,
} from './TokenStake.styled'
import { ToastContainer, toast } from 'react-toastify'
import BlockUi from '@availity/block-ui'
import Modal from 'react-modal'
import ConnectMenu from '../../utils/connect-button'
import { addNetworkByChain } from '../../utils/addNetworks'
import { defaultRPC, DEFAULT_NETWORK } from '../../utils/constants'
import { useWeb3Context } from '../../hooks'
import TokenMiniDetailAutoCompounder from './TokenMiniDetailAutoCompounder'
import { AutoCompounderActions, defaultPoolValues, PoolValuesAutoCompounder } from '../../utils/autocompounder'
import HeaderImageTokenAutoCompounder from './HeaderImageTokenAutoCompounder'
import { initWeb3 } from '../../hooks/web3/web3-context'
import CloseIcon from '../../assets/icons/close.svg'

interface TokenAutoCompounderFormProps {
  token: TokenStaking
  wrongNetwork: boolean
  onSelectToken: (token: TokenStaking) => void
  selected: boolean
  lizardPriceUsd: number,
  poolValueOfPool0: PoolValues
}

enum StakeAction {
  Stake = 'Stake',
  Unstake = 'Unstake',
}

const TokenAutoCompounderForm: FC<TokenAutoCompounderFormProps> = ({
  token,
  wrongNetwork,
  onSelectToken,
  selected,
  lizardPriceUsd,
  poolValueOfPool0
}) => {
  const { connected, web3, address, hasCachedProvider } = useWeb3Context()
  const [amount, setAmount] = useState('')
  const [disabledForm, setDisabledForm] = useState(false)
  const [stateStakeAction, setStateStakeAction] = useState<StakeAction>(StakeAction.Stake)
  const [showModal, setShowModal] = useState(false)
  const [poolValues, setPoolValues] = useState<PoolValuesAutoCompounder>(defaultPoolValues)

  const loadPoolValues = async () => {
    const newPoolValues = await AutoCompounderActions.loadValues(poolValues, lizardPriceUsd, poolValueOfPool0)
    setPoolValues(newPoolValues)
  }

  useEffect(() => {
    if (Object.keys(web3).length > 0) {
      AutoCompounderActions.web3 = web3
    } else {
      AutoCompounderActions.web3 = initWeb3(defaultRPC)
    }
  }, [web3])

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined

    const loadPoolValues = async () => {
      AutoCompounderActions.init(address, token)
      const newPoolValues = await AutoCompounderActions.loadValues(poolValues, lizardPriceUsd, poolValueOfPool0)
      setPoolValues(newPoolValues)
      interval = setInterval(async (poolValues, lizardPriceUsd, poolValueOfPool0) => {
        const newPoolValues = await AutoCompounderActions.loadValues(poolValues, lizardPriceUsd, poolValueOfPool0)
        setPoolValues(newPoolValues)
      }, 60000, poolValues, lizardPriceUsd, poolValueOfPool0)
    }

    if (
      Object.keys(AutoCompounderActions.web3).length > 0 &&
      !interval &&
      connected &&
      address.length > 0
    ) {
      loadPoolValues()
    }

    if (
      !connected &&
      address.length === 0 &&
      Object.keys(AutoCompounderActions.web3).length > 0 &&
      !hasCachedProvider()
    ) {
      loadPoolValues()
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, connected])

  const isInvalidAmount = (): boolean => {
    if (amount === '') {
      return true
    }
    const amountNumber = Number(amount)
    if (isNaN(amountNumber)) {
      return true
    }
    if (amountNumber <= 0) {
      return true
    }
    return false
  }

  const handleDeposit = async () => {
    if (isInvalidAmount()) {
      toast.error('Invalid amount')
      return
    }
    try {
      toast('Sending transaction...', {
        autoClose: false,
        toastId: 'deposit',
        type: toast.TYPE.INFO,
      })
      setDisabledForm(true)
      await AutoCompounderActions.deposit(amount)
      toast.update('deposit', {
        render: 'Deposit completed',
        autoClose: 4000,
        type: toast.TYPE.SUCCESS,
      })
      setAmount('')
      setDisabledForm(false)
      loadPoolValues()
      setShowModal(false)
    } catch (error: any) {
      setDisabledForm(false)
      toast.update('deposit', {
        render: error,
        autoClose: 4000,
        type: toast.TYPE.ERROR,
      })
      setShowModal(false)
    }
  }

  const handleWithdraw = async () => {
    if (isInvalidAmount()) {
      toast.error('Invalid amount')
      return
    }
    try {
      setDisabledForm(true)
      toast('Sending transaction...', {
        autoClose: false,
        toastId: 'withdraw',
        type: toast.TYPE.INFO,
      })
      await AutoCompounderActions.withdraw(amount)
      setAmount('')
      toast.update('withdraw', {
        render: 'Withdraw completed',
        autoClose: 4000,
        type: toast.TYPE.SUCCESS,
      })
      loadPoolValues()
      setDisabledForm(false)
      setShowModal(false)
    } catch (error: any) {
      setDisabledForm(false)
      toast.update('withdraw', {
        render: error,
        autoClose: 4000,
        type: toast.TYPE.ERROR,
      })
      setShowModal(false)
    }
  }

  const handleApproveToken = async () => {
    try {
      setDisabledForm(true)
      toast('Sending transaction...', {
        autoClose: false,
        toastId: 'approve',
        type: toast.TYPE.INFO,
      })
      await AutoCompounderActions.approveToken()
      loadPoolValues()
      toast('Token approved...', {
        autoClose: false,
        toastId: 'approve',
        type: toast.TYPE.SUCCESS,
      })
      setDisabledForm(false)
    } catch (error: any) {
      toast.update('approve', {
        render: error,
        autoClose: 4000,
        type: toast.TYPE.ERROR,
      })
      setDisabledForm(false)
      setShowModal(false)
    }
  }

  const closeModal = () => {
    setShowModal(false)
    setAmount('')
  }

  const modalStake = () => {
    return (
      <Modal
        isOpen={showModal}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        <ModalContent>
          <ModalHeader>
            <h3>{stateStakeAction === StakeAction.Stake ? 'Stake' : 'Unstake'}</h3>
            <button onClick={closeModal}>
              <img src={CloseIcon} alt="close" style={{ width: '1.25rem' }} />
            </button>
          </ModalHeader>

          <div className='d-flex justify-content-between w-100 pb-2'>
            <span>Amount</span>
            {stateStakeAction === StakeAction.Stake ? (
              <span>
                {formatValueWithoutCommaCero(poolValues.lizardTokenBalance || '0', 18, 2, 2)}
                <TextSmall>{token.symbol}</TextSmall>
              </span>
            ) : (
              <span>
                {formatNumber(poolValues.xLizardBalance || '0', 2)}
                <TextSmall>{token.autoCompounderSymbol}</TextSmall>
              </span>
            )}
          </div>
          {stateStakeAction === StakeAction.Unstake && <div className='d-flex justify-content-between w-100 pb-2'>
            <span>You will receive</span>
            <span>
              {formatNumber(poolValues.xLizardValueInLiz || '0', 2)}
              <TextSmall>{token.symbol}</TextSmall>
            </span>
          </div>}

          <InputAmount>
            <input
              type='number'
              inputMode='decimal'
              autoComplete='off'
              value={amount}
              className='form-control'
              aria-label='Token Amount'
              title='Token Amount'
              autoCorrect='off'
              pattern='^[0-9]*[.,]?[0-9]*$'
              placeholder='0.0'
              minLength={1}
              maxLength={79}
              spellCheck={false}
              onChange={(e) => {
                setAmount(e.target.value)
              }}
            />
            <button
              className='btn btn-dark'
              onClick={() => {
                setAmount(
                  formatValueWithoutComma(
                    stateStakeAction === StakeAction.Stake
                      ? poolValues.lizardTokenBalance
                      : poolValues.xLizardBalanceBig
                  )
                )
              }}
            >
              MAX
            </button>
          </InputAmount>

          {connected ? (
            poolValues.isApproved ? (
              stateStakeAction === StakeAction.Stake ? (
                <Button
                  className='btn btn-dark'
                  disabled={!poolValues.isSufficientBalance}
                  onClick={handleDeposit}
                >
                  {!poolValues.isSufficientBalance ? 'Insufficient amount' : 'Stake'}
                </Button>
              ) : (
                <Button
                  className='btn btn-dark'
                  disabled={!poolValues.hasStaked}
                  onClick={handleWithdraw}
                >
                  {!poolValues.hasStaked ? 'Insufficient amount' : 'Unstake'}
                </Button>
              )
            ) : (
              <Button className='btn btn-dark' onClick={handleApproveToken}>
                Approve {token.symbol}
              </Button>
            )
          ) : (
            <ConnectMenu reload />
          )}
        </ModalContent>
      </Modal>
    )
  }

  const buttonsStakeUnstake = () => {
    return (
      <>
        <Button
          className='btn btn-dark'
          disabled={!poolValues.isSufficientBalance}
          onClick={() => {
            setShowModal(true)
            setStateStakeAction(StakeAction.Stake)
          }}
        >
          {!poolValues.isSufficientBalance ? 'Insufficient amount' : 'Stake'}
        </Button>
        <Button
          className='btn btn-success'
          onClick={() => {
            setShowModal(true)
            setStateStakeAction(StakeAction.Unstake)
          }}
        >
          Unstake
        </Button>
      </>
    )
  }

  const handleSelectToken = (token: TokenStaking) => {
    onSelectToken(token)
  }

  if (!selected) {
    return (
      <BlockUi blocking={disabledForm}>
        <TokenMiniDetailAutoCompounder
          token={token}
          poolValues={poolValues}
          onSelect={handleSelectToken}
        />
        <ToastContainer />
      </BlockUi>
    )
  }

  return (
    <>
      <BlockUi blocking={disabledForm}>
        <BoxWrap>
          <BoxSpace className='card h-100'>
            <HeaderImageTokenAutoCompounder token={token} ratio={poolValues.ratio} />
            <div className='mb-4'>
              <Balances className='mt-8'>
                <BodyItem>
                  <BodyItemTitle>YOUR STAKE</BodyItemTitle>
                  <BodyItemValue>
                    {formatNumber(poolValues.xLizardValueInLiz, 2)}
                    <TextSmall>{token.symbol}</TextSmall>
                    <TextPriceLP>
                      ~{' '}
                      {formatNumber(
                        lizardPriceUsd * poolValues.xLizardValueInLiz
                        , 3)}{' '}
                      USD
                    </TextPriceLP>
                  </BodyItemValue>
                </BodyItem>
                <BodyItem>
                  <BodyItemTitle>AVAILABLE TO DEPOSIT</BodyItemTitle>
                  <BodyItemValue>
                    {formatValueWithoutCommaCero(poolValues.lizardTokenBalance || '0', 18, 2, 2)}
                    <TextSmall>{token.symbol}</TextSmall>
                    <TextPriceLP>
                      ~{' '}
                      {formatNumber(
                        lizardPriceUsd *
                        parseFloat(formatValueWithoutCommaCero(poolValues.lizardTokenBalance || '0', 18))
                        , 3)}{' '}
                      USD
                    </TextPriceLP>
                  </BodyItemValue>
                </BodyItem>
              </Balances>
            </div>

            {connected ? (
              wrongNetwork ? (
                <div className='text-center'>
                  <h2>Wrong Network</h2>
                  <button
                    onClick={() => {
                      if (connected) addNetworkByChain(web3, DEFAULT_NETWORK)
                    }}
                    className='btn btn-dark'
                  >
                    Change Network
                  </button>
                </div>
              ) : poolValues.isApproved ? (
                buttonsStakeUnstake()
              ) : (
                <Button className='btn btn-dark' onClick={handleApproveToken}>
                  Approve {token.symbol}
                </Button>
              )
            ) : (
              <ConnectMenu reload />
            )}
          </BoxSpace>
          <div>
            <Box className='card mb-3'>
              <Body>
                <BodyItem>
                  <BodyItemTitle>Your Share</BodyItemTitle>
                  <BodyItemValue>{formatNumber(poolValues.sharedPercentage, 2) || 0}%</BodyItemValue>
                </BodyItem>
                <BodyItem>
                  <BodyItemTitle>APY</BodyItemTitle>
                  <BodyItemValue>{formatNumber(poolValues.apy, 1) || 0}%</BodyItemValue>
                </BodyItem>
              </Body>
            </Box>
            <Box className='card mb-3'>
              <Body>
                <BodyItem>
                  <BodyItemTitle>Total Staked</BodyItemTitle>
                  <BodyItemValue aria-label='aimc'>
                    {formatNumber(formatValueWithoutCommaCero(poolValues.xLizardTotalSupply || '0', 18, 2, 2), 2)}
                    <TextSmall>{token.autoCompounderSymbol}</TextSmall>
                  </BodyItemValue>
                </BodyItem>
                <BodyItem>
                  <BodyItemTitle>TVL</BodyItemTitle>
                  <BodyItemValue>${formatNumber(poolValues.tvl)}</BodyItemValue>
                </BodyItem>
              </Body>
            </Box>
            <Box className='card'>
              <Body>
                <BodyItem>
                  <BodyItemTitle>Total pending rewards</BodyItemTitle>
                  <BodyItemValue aria-label='aimc'>
                    {formatNumber(poolValues.xLizardPendingRewards, 2)}
                    <TextSmall>{token.symbol}</TextSmall>
                  </BodyItemValue>
                </BodyItem>
                <BodyItem>
                  <BodyItemTitle>Your Pending Rewards</BodyItemTitle>
                  <BodyItemValue>
                    {formatNumber(poolValues.xLizardPendingRewardsByUser, 2)}
                    <TextSmall>{token.symbol}</TextSmall>
                  </BodyItemValue>
                </BodyItem>
              </Body>
            </Box>
          </div>
        </BoxWrap>
      </BlockUi>
      <ToastContainer />
      {modalStake()}
    </>
  )
}

export default TokenAutoCompounderForm
