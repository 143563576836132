import { Routes } from 'utils/routes'
import { MenuItem } from './MenuItem'

export function MenuInner() {
  return (
    <>
      <MenuItem title='Swap' href={Routes.Swap} />
      <MenuItem title='Pool' href={Routes.Pool} />
      <MenuItem title='Farm' to={"/"} />
      <MenuItem title='Lair' to={"/lair"} />
      <MenuItem title='King of the hill' href={Routes.Koth} />
      <MenuItem title='Charts' href={Routes.Info} target="_blank" />
    </>
  )
}