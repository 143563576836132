import Web3 from 'web3'

// Tokens
export const lizardAddress = '0x6a977d5f48D57eF125C98469D511Ef4E0cE44E10'
export const lhodlTokenAddress = '0x51f712845360FB0645a9d893d0f3D73826FFB4bD'
export const usdcAddress = '0xe8a638b3b7565ee7c5eb9755e58552afc87b94dd'
export const usdtAddress = '0xdc19a122e268128b5ee20366299fc7b5b199c8e3'
export const ethAddress = '0x3223f17957ba502cbe71401d55a0db26e5f7c68f'
export const btcAddress = '0xd43ce0aa2a29DCb75bDb83085703dc589DE6C7eb'
export const bnbAddress = '0xd79Ef9A91b56c690C7b80570a3c060678667f469'
export const xLizardAddress = '0x48aCbAD6cCc888cb4F3c2c036D2359d5921955dB' // new
export const wRoseAddress = "0x21C718C22D52d0F3a789b752D4c2fD5908a8A733";
// Farm functions
export const MasterChefAddress = '0x177FA2e64Ec14d8a845Af1B19B6b9E40053d0F72'
export const MultiCallAddress = '0xCcd7D12fAF86ae0b2829274cD2Dc960C40766947'

// Pairs
export const PairRoseLizardAddress = '0x3992B313c956d058Fae8147EF7Fe20e0cF3f13eB'
export const PairUsdcLhodlAddress = '0x07928ec31f1e2094DEd3C5ade56202ca5B38D1A6'
export const PairRoseUsdAddress = '0x90Ba41e455818BB1679a49264a6b3d7B107DCD60'
export const PairRoseETHAddress = '0x6c96abc7f9af918c8ef8c44c69f7c23bac82d41c'
export const PairUsdcETHAddress = '0x031aedc1a2d5b719458ae3785b480ae6af47575e'
export const PairUsdtUsdcAddress = '0xc7ecba8241a7dded1f173f57f370892dee1475e2'
export const PairRoseUsdcAddress = '0xb963860c513d4f0e441f8b6bf86691af2d8db848'
export const PairUsdtETHAddress = '0x6ece23d9dcdbb2fcc68044debac3f77a6c703052'
export const PairRoseBTCAddress = '0xD9A4488c9517D73604DDf1aE0cD26a2c343BB25E'
export const PairRoseBnbAddress = '0xF4810735c960AF78C66d60DB37E2dCa5DAd59a0f'
// Constants
export const tokenDecimals = 18
export const maxAllowance =
  '115792089237316195423570985008687907853269984665640564039457584007913129639935'

export enum Network {
  BSC_TESTNET = 97,
  OASIS = 42262,
  OASIS_TESTNET = 42261,
  GANACHE = 1338,
}

export const DEFAULT_NETWORK = Network.OASIS
export const emeraldRPC = 'https://emerald.oasis.dev'
export const defaultRPC = new Web3.providers.HttpProvider(emeraldRPC)

export const BUNDLE_ID = '1'
export const FACTORY_ADDRESS = '0xefA6861931991CCE372c477a015619A21dfEBE8c'

export const isLairDomain = window.location.hostname.includes('lair.')