import { FC } from 'react'
import { useWeb3Context } from '../../hooks'
import { PoolValues, TokenStaking } from '../../utils/farm'
import { formatValueWithoutCommaCero, formatNumber } from '../../utils/format'
import HeaderImageToken from './HeaderImageToken'
import { Body, BodyButtons, BtnLizard, BtnLizard2, ButtonsTrader, Card } from './TokenMiniDetail.styled'
import ReactTooltip from 'react-tooltip';
import { BodyItem, BodyItemTitle, BodyItemValue, TextExtra2Small, TextExtraSmall, TextSmall } from './TokenStake.styled'
import QuestionMark from '../../assets/icons/question-mark.svg'

interface TokenMiniDetailProps {
  token: TokenStaking
  onSelect: (token: TokenStaking) => void
  poolValues: PoolValues
  handleHarvest: () => void
}

const lizardPoolIndex = "0"
const lhodlPoolIndex = "8"

const nativePoolsIndexes = [lizardPoolIndex, lhodlPoolIndex]


const TokenMiniDetail: FC<TokenMiniDetailProps> = ({ token, onSelect, poolValues, handleHarvest, }) => {
  const { connected, } = useWeb3Context()
  const handleOpenToStake = () => {
    onSelect(token)
  }

  const handleTrade = () => {
    let url = `https://app.lizard.exchange/swap?inputCurrency=${token.token0Address}&outputCurrency=${token.token1Address}`
    if (token.index === lizardPoolIndex) {
      url = `https://app.lizard.exchange/swap?inputCurrency=ROSE&outputCurrency=${token.address}`
    }
    window.open(
      url,
      '_blank'
    )
  }

  const handleAddLiquidity = () => {
    window.open(
      `https://app.lizard.exchange/add/${token.token0Address}/${token.token1Address}`,
      '_blank'
    )
  }

  const haveStaked = parseFloat(formatValueWithoutCommaCero(poolValues.tokenStaked || '0', 18)) > 0

  return (
    <Card className="card h-100">
      <HeaderImageToken token={token} allocPoint={poolValues.info.allocPoint} />
      <Body>
        <BodyItem>
          <BodyItemTitle>TVL</BodyItemTitle>
          <BodyItemValue>${formatNumber(poolValues.tvl, 2)}</BodyItemValue>
        </BodyItem>
        <BodyItem>
          <a data-tip data-for={token.index}>
            <BodyItemTitle className='text-tooltip'>
              APR
              <div className="ms-1">
                <img src={QuestionMark} />
              </div>
            </BodyItemTitle>
          </a>
          <BodyItemValue>
            {0}%
            <ReactTooltip id={token.index} aria-haspopup='true' effect="solid">
              <TextExtraSmall>
                Base APY*: <strong>{formatNumber(poolValues.baseApy * 0.66, 1)}%</strong>
              </TextExtraSmall>
              <TextExtraSmall>
                Rewards APR: <strong>{formatNumber(poolValues.apr, 1)}%</strong>
              </TextExtraSmall>
              <TextExtra2Small>
                *Based on last 24h volume
              </TextExtra2Small>
            </ReactTooltip>
          </BodyItemValue>
        </BodyItem>
        <BodyItem>
          <BodyItemTitle>Earned</BodyItemTitle>
          <BodyItemValue>
            {formatNumber(formatValueWithoutCommaCero(poolValues.pendingRewards || '0.00', 18, 2), 2)}
            <TextSmall>LIZ</TextSmall>
          </BodyItemValue>
        </BodyItem>
        <BodyItem>
          <BodyItemTitle>Your stake</BodyItemTitle>
          <BodyItemValue>
            $
            {formatNumber(
              poolValues.lpPrice *
              parseFloat(formatValueWithoutCommaCero(poolValues.tokenStaked || '0', 18))
              , 2)}{' '}
          </BodyItemValue>
        </BodyItem>
      </Body>
      <BodyButtons>
        <BtnLizard2 className='btn w-100 btn-sm' onClick={handleOpenToStake}>
          Emergency Withdraw
        </BtnLizard2>
        {connected && haveStaked && <BtnLizard className='btn w-100 btn-sm' onClick={handleHarvest}>
          Harvest
        </BtnLizard>}
      </BodyButtons>
    </Card>
  )
}

export default TokenMiniDetail
