import {Network} from './constants'

export const oasis = {
  chainId: '0xa516',
  chainName: 'Oasis',
  nativeCurrency: {
    name: 'Rose',
    symbol: 'ROSE',
    decimals: 18,
  },
  rpcUrls: ['https://emerald.oasis.dev'],
  blockExplorerUrls: ['https://explorer.emerald.oasis.dev/'],
}

export const oasisTesnet = {
  chainId: '0xa515',
  chainName: 'Oasis Tesnet',
  nativeCurrency: {
    name: 'Rose',
    symbol: 'ROSE',
    decimals: 18,
  },
  rpcUrls: ['https://testnet.emerald.oasis.dev'],
  blockExplorerUrls: ['https://testnet.explorer.emerald.oasis.dev/'],
}

export const ganache = {
  chainId: '0x539',
  chainName: 'Ganache',
  nativeCurrency: {
    name: 'Rose',
    symbol: 'ROSE',
    decimals: 18,
  },
  rpcUrls: ['https://rpc.ganache.com'],
}

const addNetwork = async (web3: any, config: any) => {
  const {chainId} = config
  const nid = web3.currentProvider.chainId
  if (nid !== chainId) {
    try {
      await web3.currentProvider.sendAsync({
        method: 'wallet_addEthereumChain',
        params: [config],
      })
    } catch (error: any) {
      throw error.message
    }
  }
}

export const addNetworkByChain = async (web3: any, chain: number) => {
  const networks = {
    [Network.OASIS]: oasis,
    [Network.OASIS_TESTNET]: oasisTesnet,
    [Network.GANACHE]: ganache,
  }
  return addNetwork(web3, (networks as any)[chain])
}
