import {extractDataOfMultiCallByField, PoolValues, TokenStaking} from './farm'
import {ethers} from 'ethers'
import {TOKEN_ABI, XLIZARD_ABI} from './abi'
import {maxAllowance, lizardAddress, MultiCallAddress} from './constants'
import {ContractCallContext, ContractCallResults, Multicall} from 'ethereum-multicall'

export interface PoolValuesAutoCompounder {
  apr: string
  liquidity: string
  sharedPercentage: number // user
  pendingRewards: string // user
  bonusRewards: string // user
  lizardTokenBalance: string // user
  tokenStaked: string // user
  address: string // user
  isApproved: boolean // user
  isSufficientBalance: boolean // user
  totalStaked: string
  hasStaked: boolean
  tvl: number
  lpPrice: number
  ratio: string
  performanceFee: number
  totalStakedInUsd: string
  apy: number
  xLizardBalance: number
  xLizardValueInLiz: number
  pricePerFullShare: number
  xLizardTotalSupply: string
  xLizardBalanceBig: any
  xLizardPendingRewards: number
  xLizardPendingRewardsByUser: number
}

export const defaultPoolValues: PoolValuesAutoCompounder = {
  apr: '0.0',
  liquidity: '0.0',
  sharedPercentage: 0,
  pendingRewards: '0.0',
  bonusRewards: '0.0',
  lizardTokenBalance: '0.0',
  tokenStaked: '0.0',
  address: '',
  isApproved: false,
  isSufficientBalance: false,
  totalStaked: '0.0',
  hasStaked: false,
  tvl: 0,
  lpPrice: 0,
  ratio: '0',
  performanceFee: 0,
  totalStakedInUsd: '0',
  apy: 0,
  xLizardBalance: 0,
  xLizardValueInLiz: 0,
  pricePerFullShare: 0,
  xLizardTotalSupply: '0',
  xLizardBalanceBig: '0',
  xLizardPendingRewards: 0,
  xLizardPendingRewardsByUser: 0,
}

const getMultiCallRequest = (
  tokenToRewardsAddress: string,
  userAddress: string
): ContractCallContext[] => {
  const multiCallRequest: ContractCallContext[] = [
    {
      reference: 'performanceFee',
      contractAddress: tokenToRewardsAddress,
      abi: XLIZARD_ABI,
      calls: [
        {
          reference: 'performanceFee',
          methodName: 'performanceFee',
          methodParameters: [],
        },
      ],
    },
    {
      reference: 'pricePerFullShare',
      contractAddress: tokenToRewardsAddress,
      abi: XLIZARD_ABI,
      calls: [
        {
          reference: 'pricePerFullShare',
          methodName: 'getPricePerFullShare',
          methodParameters: [],
        },
      ],
    },
    {
      reference: 'xLizardTotalSupply',
      contractAddress: tokenToRewardsAddress,
      abi: XLIZARD_ABI,
      calls: [
        {
          reference: 'xLizardTotalSupply',
          methodName: 'totalSupply',
          methodParameters: [],
        },
      ],
    },
    {
      reference: 'xLizardPendingRewards',
      contractAddress: tokenToRewardsAddress,
      abi: XLIZARD_ABI,
      calls: [
        {
          reference: 'xLizardPendingRewards',
          methodName: 'calculateTotalPendingLizardRewards',
          methodParameters: [],
        },
      ],
    },

    ...(userAddress.length > 0
      ? [
          {
            reference: 'allowance',
            contractAddress: lizardAddress,
            abi: TOKEN_ABI,
            calls: [
              {
                reference: 'allowance',
                methodName: 'allowance',
                methodParameters: [userAddress, tokenToRewardsAddress],
              },
            ],
          },
          {
            reference: 'balanceOfLizard',
            contractAddress: lizardAddress,
            abi: TOKEN_ABI,
            calls: [
              {
                reference: 'balanceOfLizard',
                methodName: 'balanceOf',
                methodParameters: [userAddress],
              },
            ],
          },
          {
            reference: 'xLizardBalance',
            contractAddress: tokenToRewardsAddress,
            abi: XLIZARD_ABI,
            calls: [
              {
                reference: 'xLizardBalance',
                methodName: 'balanceOf',
                methodParameters: [userAddress],
              },
            ],
          },
        ]
      : []),
  ]
  return multiCallRequest
}

export class AutoCompounderActions {
  public static contract: any
  public static token: TokenStaking
  public static userAddress: string
  public static lizardContract: any
  public static tokenToAutoCompounderContract: any
  public static web3: any

  static init(userAddress: string, token: TokenStaking) {
    AutoCompounderActions.lizardContract = new AutoCompounderActions.web3.eth.Contract(
      TOKEN_ABI,
      lizardAddress
    )
    AutoCompounderActions.tokenToAutoCompounderContract =
      new AutoCompounderActions.web3.eth.Contract(XLIZARD_ABI, token.rewardsTokenAddress)
    AutoCompounderActions.userAddress = userAddress
    AutoCompounderActions.token = token
  }

  static async loadValues(
    poolValues: PoolValuesAutoCompounder,
    lizardPriceUsd: number,
    poolValueOfPool0: PoolValues
  ): Promise<PoolValuesAutoCompounder> {
    const newPoolValues: PoolValuesAutoCompounder = {...poolValues}
    const multicall = new Multicall({
      web3Instance: AutoCompounderActions.web3,
      multicallCustomContractAddress: MultiCallAddress,
    })
    const requests = getMultiCallRequest(
      AutoCompounderActions.token.rewardsTokenAddress as string,
      AutoCompounderActions.userAddress
    )
    const results: ContractCallResults = await multicall.call(requests)
    const {
      performanceFee,
      pricePerFullShare,
      allowance,
      balanceOfLizard,
      xLizardBalance,
      xLizardTotalSupply,
      xLizardPendingRewards,
    } = extractDataOfMultiCallByField(
      results,
      requests.map((el) => el.reference)
    )
    newPoolValues.lizardTokenBalance = balanceOfLizard
    newPoolValues.isSufficientBalance =
      Number(ethers.utils.formatUnits(balanceOfLizard || '0', 18)) > 0
    newPoolValues.isApproved = Number(ethers.utils.formatUnits(allowance || '0')) > 0
    newPoolValues.performanceFee = Number(ethers.utils.formatUnits(performanceFee))
    newPoolValues.pricePerFullShare = Number(ethers.utils.formatUnits(pricePerFullShare))
    newPoolValues.xLizardBalance = Number(ethers.utils.formatUnits(xLizardBalance || '0'))
    newPoolValues.xLizardBalanceBig = xLizardBalance
    newPoolValues.xLizardValueInLiz = newPoolValues.xLizardBalance * newPoolValues.pricePerFullShare // cantidad de lizard
    newPoolValues.xLizardTotalSupply = xLizardTotalSupply
    newPoolValues.xLizardPendingRewards = Number(
      ethers.utils.formatUnits(xLizardPendingRewards || '0')
    )

    if (newPoolValues.xLizardBalance > 0) {
      newPoolValues.sharedPercentage =
        (newPoolValues.xLizardBalance / Number(ethers.utils.formatUnits(xLizardTotalSupply))) * 100

      newPoolValues.xLizardPendingRewardsByUser =
        newPoolValues.xLizardPendingRewards * (newPoolValues.sharedPercentage / 100)
    }
    newPoolValues.hasStaked = newPoolValues.xLizardValueInLiz > 0

    newPoolValues.tvl =
      lizardPriceUsd *
      Number(ethers.utils.formatUnits(xLizardTotalSupply)) *
      newPoolValues.pricePerFullShare

    const ratioNumber = Number(ethers.utils.formatUnits(pricePerFullShare)).toFixed(2)
    newPoolValues.ratio = `1 xLiz = ${ratioNumber} ${AutoCompounderActions.token.symbol}`
    const secondsInYear = 31557600
    newPoolValues.apy =
      ((1 + Number(poolValueOfPool0.apr) / 100 / secondsInYear) ** secondsInYear - 1) * 100
    return newPoolValues
  }

  static approveToken = async () => {
    try {
      await AutoCompounderActions.lizardContract.methods
        .approve(AutoCompounderActions.token.rewardsTokenAddress, maxAllowance)
        .send({from: AutoCompounderActions.userAddress, gasPrice: 225000000000})
    } catch (error: any) {
      throw error.message
    }
  }

  static deposit = async (amount: string): Promise<void> => {
    try {
      const parsedAmount = ethers.utils.parseUnits(amount, AutoCompounderActions.token.decimals)
      await AutoCompounderActions.tokenToAutoCompounderContract.methods
        .deposit(parsedAmount)
        .send({from: AutoCompounderActions.userAddress})
    } catch (error: any) {
      throw error.message
    }
  }

  static withdraw = async (amount: string): Promise<void> => {
    try {
      const parsedAmount = ethers.utils.parseUnits(amount, AutoCompounderActions.token.decimals)
      await AutoCompounderActions.tokenToAutoCompounderContract.methods
        .withdraw(parsedAmount)
        .send({from: AutoCompounderActions.userAddress})
    } catch (error: any) {
      throw error.message
    }
  }
}
