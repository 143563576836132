import { FC } from 'react'
import { MenuInner } from './MenuInner'
import styled from 'styled-components'

const Menu = styled.div`
  @media(max-width: 768px) {
    margin-left: -3rem;
  }
`

const Header: FC = (): JSX.Element => {
  return (
    <Menu className='header-menu align-items-center w-100'>
      <div
        className='menu menu-lg-rounded  menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-0 my-md-5 my-lg-0 align-items-stretch'
        id='#kt_header_menu'
        data-kt-menu='true'
      >
        <MenuInner />
      </div>
    </Menu>
  )
}

export {Header}
