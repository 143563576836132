import {
  MasterChefAddress,
  maxAllowance,
  MultiCallAddress,
  Network,
  lhodlTokenAddress,
  PairRoseETHAddress,
  PairRoseLizardAddress,
  PairRoseUsdAddress,
  PairRoseUsdcAddress,
  PairUsdcETHAddress,
  PairUsdcLhodlAddress,
  PairUsdtETHAddress,
  PairUsdtUsdcAddress,
  lizardAddress,
  usdcAddress,
  usdtAddress,
  ethAddress,
  btcAddress,
  bnbAddress,
  PairRoseBnbAddress,
  PairRoseBTCAddress,
  xLizardAddress,
} from './constants'
import * as ethers from 'ethers'
import {LP_ABI, MASTER_CHEF_ABI, TOKEN_ABI} from './abi'
import {formatValueWithoutCommaCero} from './format'
import {Multicall, ContractCallResults, ContractCallContext} from 'ethereum-multicall'
import {getPair, getTokenByAddress} from './apollo-requests'

interface LPToken {
  name: string
  symbol: string
  image: string
}

export interface TokenInterface {
  name: string
  index?: string
  decimals: number
  isLp: boolean
  native?: boolean
  image?: string
  token0?: LPToken
  token1?: LPToken
  symbol: string
  isAutoCompounder?: boolean
  rewardsTokenAddress?: string
  autoCompounderSymbol?: string
}

export interface PoolValues {
  apr: string
  liquidity: string
  sharedPercentage: string // user
  pendingRewards: string // user
  bonusRewards: string // user
  tokenBalance: string // user
  tokenStaked: string // user
  address: string // user
  isApproved: boolean // user
  isSufficientBalance: boolean // user
  actions: LpActions
  info: GPoolInfo
  totalStaked: string
  hasStaked: boolean
  tvl: number
  lpPrice: number
  baseApy: number
  token: TokenStaking
}

enum Tokens {
  LAIR = 'Lair',
  ROSE_LAIR = 'Rose & Lizard',
  ROSE_USDT = 'Rose & USDT',
  ROSE_ETH = 'Rose & ETH',
  USDT_USDC = 'USDT & USDC',
  ETH_USDT = 'ETH & USDT',
  ROSE_USDC = 'ROSE & USDC',
  USDC_ETH = 'USDC & ETH',
  ROSE_BTC = 'BTC & ROSE',
  ROSE_BNB = 'BNB & ROSE',
  LHODL = 'LHODL',
  XLIZARD = 'XLIZARD',
}

export const Token = {
  [Tokens.XLIZARD]: {
    name: 'xLizard Pool',
    symbol: 'LIZ',
    decimals: 18,
    isLp: false,
    isAutoCompounder: true,
    autoCompounderSymbol: 'xLIZ',
    image: '/media/coins/xlizard.jpg',
  },
  [Tokens.LAIR]: {
    name: 'Lizard Pool',
    symbol: 'LIZ',
    decimals: 18,
    isLp: false,
    image: '/media/coin.png',
  },
  [Tokens.LHODL]: {
    name: 'LHODL Pool',
    symbol: 'LHODL',
    decimals: 18,
    isLp: false,
    image: '/media/coins/lhodl.png',
  },
  [Tokens.ROSE_LAIR]: {
    name: 'LIZ–ROSE',
    symbol: 'LIZ/ROSE',
    decimals: 18,
    isLp: true,
    native: true,
    image: '/media/coins/usdt.png',
    token1: {
      name: 'ROSE',
      symbol: 'ROSE',
      image: '/media/coins/rose.png',
    },
    token0: {
      name: 'Lizard',
      symbol: 'LIZ',
      image: '/media/coin.png',
    },
  },
  [Tokens.ROSE_USDT]: {
    name: 'ROSE–USDT',
    symbol: 'ROSE/USDT',
    decimals: 18,
    isLp: true,
    native: true,
    image: '/media/coins/usdt.png',
    token0: {
      name: 'ROSE',
      symbol: 'ROSE',
      image: '/media/coins/rose.png',
    },
    token1: {
      name: 'USDT',
      symbol: 'USDT',
      image: '/media/coins/usdt.png',
    },
  },
  [Tokens.ROSE_ETH]: {
    name: 'ROSE–ETH',
    symbol: 'ROSE/ETH',
    decimals: 18,
    isLp: true,
    native: true,
    image: '/media/coins/usdt.png',
    token0: {
      name: 'ROSE',
      symbol: 'ROSE',
      image: '/media/coins/rose.png',
    },
    token1: {
      name: 'ETH',
      symbol: 'ETH',
      image: '/media/coins/eth.png',
    },
  },
  [Tokens.USDC_ETH]: {
    name: 'USDC–ETH',
    symbol: 'USDC/ETH',
    decimals: 18,
    isLp: true,
    image: '/media/coins/usdc.png',
    token0: {
      name: 'USDC',
      symbol: 'USDC',
      image: '/media/coins/usdc.png',
    },
    token1: {
      name: 'ETH',
      symbol: 'ETH',
      image: '/media/coins/eth.png',
    },
  },
  [Tokens.USDT_USDC]: {
    name: 'USDC–USDT',
    symbol: 'USDC/USDT',
    decimals: 18,
    isLp: true,
    image: '/media/coins/usdc.png',
    token0: {
      name: 'USDC',
      symbol: 'USDC',
      image: '/media/coins/usdc.png',
    },
    token1: {
      name: 'USDT',
      symbol: 'USDT',
      image: '/media/coins/usdt.png',
    },
  },
  [Tokens.ROSE_USDC]: {
    name: 'ROSE–USDC',
    symbol: 'ROSE/USDC',
    decimals: 18,
    isLp: true,
    native: true,
    image: '/media/coins/usdt.png',
    token0: {
      name: 'ROSE',
      symbol: 'ROSE',
      image: '/media/coins/rose.png',
    },
    token1: {
      name: 'USDT',
      symbol: 'USDC',
      image: '/media/coins/usdc.png',
    },
  },
  [Tokens.ETH_USDT]: {
    name: 'ETH–USDT',
    symbol: 'ETH/USDT',
    decimals: 18,
    isLp: true,
    native: true,
    image: '/media/coins/usdt.png',
    token0: {
      name: 'ETH',
      symbol: 'ETH',
      image: '/media/coins/eth.png',
    },
    token1: {
      name: 'USDT',
      symbol: 'USDT',
      image: '/media/coins/usdt.png',
    },
  },
  [Tokens.ROSE_BTC]: {
    name: 'ROSE–BTC',
    symbol: 'ROSE/BTC',
    decimals: 18,
    isLp: true,
    native: true,
    image: '/media/coins/usdt.png',
    token1: {
      name: 'BTC',
      symbol: 'BTC',
      image: '/media/coins/wbtc.png',
    },
    token0: {
      name: 'ROSE',
      symbol: 'ROSE',
      image: '/media/coins/rose.png',
    },
  },
  [Tokens.ROSE_BNB]: {
    name: 'ROSE–BNB',
    symbol: 'ROSE/BNB',
    decimals: 18,
    isLp: true,
    native: true,
    image: '/media/coins/usdt.png',
    token0: {
      name: 'ROSE',
      symbol: 'ROSE',
      image: '/media/coins/rose.png',
    },
    token1: {
      name: 'BNB',
      symbol: 'BNB',
      image: '/media/coins/bnb.png',
    },
  },
}

export interface TokenStaking extends TokenInterface {
  address: string
  index: string
  token0Address?: string
  token1Address?: string
}
export interface TokenList {
  [key: number]: TokenStaking[]
}

export const TokensList: TokenList = {
  [Network.OASIS]: [
    {
      ...Token[Tokens.XLIZARD],
      index: '10000',
      address: lizardAddress,
      rewardsTokenAddress: xLizardAddress,
    },
    {
      ...Token[Tokens.LAIR],
      index: '0',
      address: lizardAddress,
    },
    {
      ...Token[Tokens.LHODL],
      index: '8',
      address: lhodlTokenAddress,
      token0Address: usdcAddress,
      token1Address: lhodlTokenAddress,
    },
    {
      ...Token[Tokens.ROSE_LAIR],
      index: '1',
      address: PairRoseLizardAddress,
      token0Address: 'ROSE',
      token1Address: lizardAddress,
    },
    {
      ...Token[Tokens.ROSE_USDT],
      index: '2',
      address: PairRoseUsdAddress,
      token0Address: 'ROSE',
      token1Address: usdtAddress,
    },
    {
      ...Token[Tokens.ROSE_USDC],
      index: '6',
      address: PairRoseUsdcAddress,
      token0Address: 'ROSE',
      token1Address: usdcAddress,
    },
    {
      ...Token[Tokens.ROSE_ETH],
      index: '3',
      address: PairRoseETHAddress,
      token0Address: 'ROSE',
      token1Address: ethAddress,
    },
    {
      ...Token[Tokens.ETH_USDT],
      index: '7',
      address: PairUsdtETHAddress,
      token0Address: ethAddress,
      token1Address: usdtAddress,
    },
    {
      ...Token[Tokens.ROSE_BNB],
      index: '10',
      address: PairRoseBnbAddress,
      token0Address: 'ROSE',
      token1Address: bnbAddress,
    },
    {
      ...Token[Tokens.ROSE_BTC],
      index: '9',
      address: PairRoseBTCAddress,
      token0Address: 'ROSE',
      token1Address: btcAddress,
    },
    {
      ...Token[Tokens.USDC_ETH],
      index: '4',
      address: PairUsdcETHAddress,
      token0Address: usdcAddress,
      token1Address: ethAddress,
    },
    {
      ...Token[Tokens.USDT_USDC],
      index: '5',
      address: PairUsdtUsdcAddress,
      token0Address: usdcAddress,
      token1Address: usdtAddress,
    },
  ],
  [Network.OASIS_TESTNET]: [
    {...Token[Tokens.LAIR], index: '0', address: lizardAddress},
    {...Token[Tokens.ROSE_LAIR], index: '1', address: PairUsdcLhodlAddress},
    {...Token[Tokens.ROSE_USDT], index: '2', address: PairRoseUsdAddress},
    {...Token[Tokens.ROSE_ETH], index: '3', address: PairRoseETHAddress},
    {...Token[Tokens.USDC_ETH], index: '4', address: PairUsdcETHAddress},
    {...Token[Tokens.USDT_USDC], index: '5', address: PairUsdtUsdcAddress},
    {...Token[Tokens.ROSE_USDC], index: '6', address: PairRoseUsdcAddress},
    {...Token[Tokens.ETH_USDT], index: '7', address: PairUsdtETHAddress},
  ],
  [Network.GANACHE]: [
    {
      ...Token[Tokens.XLIZARD],
      index: '10000',
      address: '0x67B77D253b33c6D7c4D62a59aC2291732F164cc9',
      rewardsTokenAddress: '0x811726321211aC305Acf29A26fBD833E29567a06',
    },
    {
      ...Token[Tokens.LAIR],
      index: '0',
      address: '0x67B77D253b33c6D7c4D62a59aC2291732F164cc9',
    },
    {
      ...Token[Tokens.LHODL],
      index: '8',
      address: '0x67B77D253b33c6D7c4D62a59aC2291732F164cc9',
      token0Address: usdcAddress,
      token1Address: lhodlTokenAddress,
    },
    {
      ...Token[Tokens.ROSE_LAIR],
      index: '1',
      address: '0xf9b2dd0Fb325A162EcCA92AC8214939F13Bb0eb4',
      token0Address: 'ROSE',
      token1Address: lizardAddress,
    },
    {
      ...Token[Tokens.ROSE_USDT],
      index: '2',
      address: '0x4d7BEE9d0cf0cC3cA9A67A0228EC5E2F31760d78',
      token0Address: 'ROSE',
      token1Address: usdtAddress,
    },
    {
      ...Token[Tokens.ROSE_USDC],
      index: '6',
      address: '0x5D226510990cfEa1d58C29Ea95efA459afEF47a6',
      token0Address: 'ROSE',
      token1Address: usdcAddress,
    },
    {
      ...Token[Tokens.ROSE_ETH],
      index: '3',
      address: '0xDC1C9f05023e69E2c685B1DB78FBb644e2B3baF3',
      token0Address: 'ROSE',
      token1Address: ethAddress,
    },
    {
      ...Token[Tokens.ETH_USDT],
      index: '7',
      address: '0x33b9E97af65Ad45626B616023E1D073eF3336d05',
      token0Address: ethAddress,
      token1Address: usdtAddress,
    },
    {
      ...Token[Tokens.USDC_ETH],
      index: '4',
      address: '0x33b9E97af65Ad45626B616023E1D073eF3336d05',
      token0Address: usdcAddress,
      token1Address: ethAddress,
    },
  ],
}

interface UserInfoOfPool {
  amount: string
  rewardDebt: string
}

export interface GPoolInfo {
  0: string
  1: string
  2: string
  3: string
  4: string
  5: string
  lpToken: string
  allocPoint: string
  lastRewardTime: string
  accLizardPerShare: string
  depositFeeBP: string
  totalDeposit: string
}

class MasterChef {
  private address: string
  private token: TokenStaking
  private masterChef: any

  constructor(address: string, token: TokenStaking, masterChef: any) {
    this.address = address
    this.token = token
    this.masterChef = masterChef
  }

  depositToPool = async (amount: string): Promise<void> => {
    try {
      const parsedAmount = ethers.utils.parseUnits(amount, this.token.decimals)
      await this.masterChef.methods
        .deposit(this.token.index, parsedAmount)
        .send({from: this.address})
    } catch (error: any) {
      throw error.message
    }
  }

  userInfoOfPool = async (): Promise<UserInfoOfPool> => {
    try {
      return await this.masterChef.methods.userInfo(this.token.index, this.address).call()
    } catch (error: any) {
      throw error.message
    }
  }

  userInfoOfPoolCall = (): ContractCallContext => {
    return {
      reference: 'userInfoOfPoolCall',
      contractAddress: MasterChefAddress,
      abi: MASTER_CHEF_ABI,
      calls: [
        {
          reference: 'userInfoOfPoolCall',
          methodName: 'userInfo',
          methodParameters: [this.token.index, this.address],
        },
      ],
    }
  }

  pendingRewardCall = (): ContractCallContext => {
    return {
      reference: 'pendingRewardCall',
      contractAddress: MasterChefAddress,
      abi: MASTER_CHEF_ABI,
      calls: [
        {
          reference: 'pendingRewardCall',
          methodName: 'pendingLizard',
          methodParameters: [this.token.index, this.address],
        },
      ],
    }
  }

  pendingReward = async (): Promise<string> => {
    try {
      return await this.masterChef.methods.pendingLizard(this.token.index, this.address).call()
    } catch (error: any) {
      throw error.message
    }
  }

  getTokenPerTime = async (): Promise<string> => {
    try {
      return await this.masterChef.methods.lizardPerTime().call()
    } catch (error: any) {
      throw error.message
    }
  }

  tokenPerTimeCall = (): ContractCallContext => {
    return {
      reference: 'tokenPerTimeCall',
      contractAddress: MasterChefAddress,
      abi: MASTER_CHEF_ABI,
      calls: [
        {
          reference: 'tokenPerTimeCall',
          methodName: 'lizardPerTime',
          methodParameters: [],
        },
      ],
    }
  }

  getPoolInfo = async (): Promise<GPoolInfo> => {
    try {
      return await this.masterChef.methods.poolInfo(this.token.index).call()
    } catch (error: any) {
      throw error.message
    }
  }

  poolInfoCall = (): ContractCallContext => {
    return {
      reference: 'poolInfoCall',
      contractAddress: MasterChefAddress,
      abi: MASTER_CHEF_ABI,
      calls: [
        {
          reference: 'poolInfoCall',
          methodName: 'poolInfo',
          methodParameters: [this.token.index],
        },
      ],
    }
  }

  geTotalAllocPoint = async (): Promise<number> => {
    try {
      return await this.masterChef.methods.totalAllocPoint().call()
    } catch (error: any) {
      throw error.message
    }
  }

  totalAllocPointCall = (): ContractCallContext => {
    return {
      reference: 'totalAllocPointCall',
      contractAddress: MasterChefAddress,
      abi: MASTER_CHEF_ABI,
      calls: [
        {
          reference: 'totalAllocPointCall',
          methodName: 'totalAllocPoint',
          methodParameters: [],
        },
      ],
    }
  }

  withdrawFromPool = async (amount: string): Promise<void> => {
    try {
      const parsedAmount = ethers.utils.parseUnits(amount, this.token.decimals)
      await this.masterChef.methods
        .withdraw(this.token.index, parsedAmount)
        .send({from: this.address})
    } catch (error: any) {
      throw error.message
    }
  }

  emergencyWithdrawFromPool = async (): Promise<void> => {
    try {
      await this.masterChef.methods
        .emergencyWithdraw(this.token.index)
        .send({from: this.address})
    } catch (error: any) {
      throw error.message
    }
  }

  getTvl = (informationOfPool: GPoolInfo, priceLp: number): number => {
    const {totalDeposit} = informationOfPool
    const tvlInUsdt = parseFloat(ethers.utils.formatUnits(totalDeposit)) * priceLp
    return tvlInUsdt
  }

  getAPR = (
    informationOfPool: GPoolInfo, // pool info by index
    totalAllocPoint: number, // total alloc point of master chef
    tokenPerTime: string, // lizard per time of master chef
    tvlInUsdt: number, // total value of liquidity in usdt = total deposit * priceLp
    priceLizardUSDT: number // price of lizard in usdt
  ): string => {
    const {allocPoint} = informationOfPool
    const secondsInAYear = 31540000
    const allocPointNumber = Number(ethers.utils.formatUnits(allocPoint, 0))
    const tokenPerTimeNumber = Number(ethers.utils.formatUnits(tokenPerTime, 18)) / 2
    const totalAllocPointNumber = Number(ethers.utils.formatUnits(totalAllocPoint, 0))
    const totalLizardInAYear =
      (allocPointNumber * secondsInAYear * tokenPerTimeNumber * priceLizardUSDT) /
      totalAllocPointNumber
    const apr = ((totalLizardInAYear / tvlInUsdt) * 100).toFixed(2)
    return apr
  }

  getLiquidity = async (informationOfPool: GPoolInfo, usdtLizardPrice: number): Promise<string> => {
    const {totalDeposit} = informationOfPool
    const liquidity = usdtLizardPrice * Number(ethers.utils.formatUnits(totalDeposit))
    return liquidity.toFixed(2)
  }
}

export class LpActions {
  static web3: any
  private address: string
  private token: TokenStaking
  public masterChef: MasterChef
  private tokenContract: any
  private gas = 225000000000
  private static contractChef = null
  private usdtLizardPrice: number = 0

  constructor(address: string, token: TokenStaking, usdtLizardPrice: number) {
    this.address = address
    this.token = token
    if (LpActions.contractChef === null) {
      LpActions.contractChef = new LpActions.web3.eth.Contract(MASTER_CHEF_ABI, MasterChefAddress)
    }
    this.tokenContract = new LpActions.web3.eth.Contract(LP_ABI, this.token.address)
    this.masterChef = new MasterChef(address, token, LpActions.contractChef)
    this.usdtLizardPrice = usdtLizardPrice
  }

  getBalanceCall = (address?: string | undefined): ContractCallContext => {
    return {
      reference: 'balanceOfCall',
      contractAddress: this.token.address,
      abi: TOKEN_ABI,
      calls: [
        {
          reference: 'balanceOfCall',
          methodName: 'balanceOf',
          methodParameters: [address ? address : this.address],
        },
      ],
    }
  }

  approveToken = async (address: string) => {
    try {
      await this.tokenContract.methods
        .approve(MasterChefAddress, maxAllowance)
        .send({from: address, gasPrice: this.gas})
    } catch (error: any) {
      throw error.message
    }
  }

  getAllowanceBalanceCall = (): ContractCallContext => {
    return {
      reference: 'allowanceCall',
      contractAddress: this.token.address,
      abi: TOKEN_ABI,
      calls: [
        {
          reference: 'allowanceCall',
          methodName: 'allowance',
          methodParameters: [this.address, MasterChefAddress],
        },
      ],
    }
  }

  getMultiCallWithoutConnectRequest = () => {
    const contractCallContext: ContractCallContext[] = [
      this.masterChef.totalAllocPointCall(),
      this.masterChef.poolInfoCall(),
      this.masterChef.tokenPerTimeCall(),
    ]
    return contractCallContext
  }

  getSharedPoolPercentage = (userInfoOfPool: UserInfoOfPool, totalDeposit: string): string => {
    const sharedPercentage =
      (Number(ethers.utils.formatUnits(userInfoOfPool.amount)) / Number(totalDeposit) ||
        Number(ethers.utils.formatUnits(userInfoOfPool.amount))) * 100
    return sharedPercentage.toFixed(2)
  }

  getPairData = async (lpAddress: string): Promise<any> => {
    try {
      const response = await getPair(lpAddress.toLocaleLowerCase())
      return response
    } catch (error) {
      return null
    }
  }

  static getUSDTPriceOfToken = async (address: string): Promise<number> => {
    try {
      const response = await getTokenByAddress(address.toLowerCase())
      return response?.priceInUsd || 0
    } catch (error) {
      return 0
    }
  }

  getMultiCallRequest = () => {
    const contractCallContext: ContractCallContext[] = [
      this.getBalanceCall(),
      this.getAllowanceBalanceCall(),
      this.masterChef.userInfoOfPoolCall(),
      this.masterChef.pendingRewardCall(),
      this.masterChef.totalAllocPointCall(),
      this.masterChef.poolInfoCall(),
      this.masterChef.tokenPerTimeCall(),
    ]
    return contractCallContext
  }

  getPoolValues = async (poolValues: PoolValues): Promise<PoolValues> => {
    const multicall = new Multicall({
      web3Instance: LpActions.web3,
      multicallCustomContractAddress: MultiCallAddress,
    })

    const pairData = !['0', '8'].includes(this.token.index)
      ? await this.getPairData(this.token.address)
      : await this.getPairData(PairRoseLizardAddress)
    poolValues.baseApy = pairData?.baseApy || 0
    if (this.address.length !== 0) {
      const results: ContractCallResults = await multicall.call(this.getMultiCallRequest())
      const {
        balanceOfCall,
        allowanceCall,
        userInfoOfPoolCall,
        pendingRewardCall,
        totalAllocPointCall,
        tokenPerTimeCall,
        poolInfoCall,
      } = extractDataOfMultiCallByField(results, [
        'balanceOfCall',
        'allowanceCall',
        'userInfoOfPoolCall',
        'pendingRewardCall',
        'totalAllocPointCall',
        'poolInfoCall',
        'tokenPerTimeCall',
      ])

      const tokenBalance = balanceOfCall || '0'
      const totalAllowance = parseFloat(ethers.utils.formatUnits(allowanceCall) || '0')
      const informationOfPool = {
        lpToken: poolInfoCall[0],
        allocPoint: poolInfoCall[1],
        lastRewardTime: poolInfoCall[2],
        accLizardPerShare: poolInfoCall[3],
        depositFeeBP: poolInfoCall[4],
        totalDeposit: poolInfoCall[5],
      } as GPoolInfo
      const userInfoOfPool = {
        amount: userInfoOfPoolCall[0],
        rewardDebt: userInfoOfPoolCall[1],
      } as UserInfoOfPool
      const totalDeposit = ethers.utils.formatUnits(informationOfPool.totalDeposit) || '0'
      poolValues.tokenBalance = tokenBalance
      poolValues.isApproved = totalAllowance > 0
      poolValues.pendingRewards = pendingRewardCall
      poolValues.isSufficientBalance = parseFloat(formatValueWithoutCommaCero(tokenBalance)) > 0
      poolValues.tokenStaked = userInfoOfPool?.amount || '0'
      poolValues.info = informationOfPool
      poolValues.totalStaked = informationOfPool.totalDeposit
      poolValues.hasStaked = parseFloat(totalDeposit) > 0
      poolValues.lpPrice = !['0', '8'].includes(this.token.index)
        ? pairData?.lpPriceUSD || 0
        : this.token.index === '8'
        ? await LpActions.getUSDTPriceOfToken(lhodlTokenAddress)
        : this.usdtLizardPrice
      poolValues.tvl = this.masterChef.getTvl(informationOfPool, poolValues.lpPrice)
      poolValues.sharedPercentage = this.getSharedPoolPercentage(userInfoOfPool, totalDeposit)
      poolValues.apr = this.masterChef.getAPR(
        informationOfPool,
        totalAllocPointCall,
        tokenPerTimeCall,
        poolValues.tvl,
        this.usdtLizardPrice
      )
      poolValues.liquidity = await this.masterChef.getLiquidity(
        informationOfPool,
        this.usdtLizardPrice
      )
    } else {
      const results: ContractCallResults = await multicall.call(
        this.getMultiCallWithoutConnectRequest()
      )
      const {totalAllocPointCall, tokenPerTimeCall, poolInfoCall} = extractDataOfMultiCallByField(
        results,
        ['totalAllocPointCall', 'poolInfoCall', 'tokenPerTimeCall']
      )

      const tokenBalance = '0'
      const informationOfPool = {
        lpToken: poolInfoCall[0],
        allocPoint: poolInfoCall[1],
        lastRewardTime: poolInfoCall[2],
        accLizardPerShare: poolInfoCall[3],
        depositFeeBP: poolInfoCall[4],
        totalDeposit: poolInfoCall[5],
      } as GPoolInfo

      const totalDeposit = ethers.utils.formatUnits(informationOfPool.totalDeposit) || '0'
      poolValues.tokenBalance = tokenBalance
      poolValues.isApproved = false
      poolValues.pendingRewards = ethers.BigNumber.from(0).toString()
      poolValues.isSufficientBalance = parseFloat(formatValueWithoutCommaCero(tokenBalance)) > 0
      poolValues.tokenStaked = ethers.BigNumber.from(0).toString()
      poolValues.info = informationOfPool
      poolValues.totalStaked = informationOfPool.totalDeposit
      poolValues.hasStaked = parseFloat(totalDeposit) > 0
      poolValues.lpPrice = !['0', '8'].includes(this.token.index)
        ? pairData?.lpPriceUSD || 0
        : this.token.index === '8'
        ? await LpActions.getUSDTPriceOfToken(lhodlTokenAddress)
        : this.usdtLizardPrice
      poolValues.tvl = this.masterChef.getTvl(informationOfPool, poolValues.lpPrice)
      poolValues.sharedPercentage = ethers.BigNumber.from(0).toString()
      poolValues.apr = this.masterChef.getAPR(
        informationOfPool,
        totalAllocPointCall,
        tokenPerTimeCall,
        poolValues.tvl,
        this.usdtLizardPrice
      )
      poolValues.liquidity = await this.masterChef.getLiquidity(
        informationOfPool,
        this.usdtLizardPrice
      )
    }
    return poolValues
  }
}

export const extractDataOfMultiCallByField = (
  {results}: ContractCallResults,
  fields: string[]
): any => {
  const data: any = {}
  fields.forEach((field: string) => {
    if (!results[field]) return
    const result = results[field].callsReturnContext[0].returnValues
    data[field] = result.length > 1 ? result : result[0]
  })
  return data
}
